import React, { useState } from "react";
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Typography,
    makeStyles,
    Container,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { connect } from "react-redux";
// Actions
import { forgotPassword } from "../../redux-helpers/Actions/authAction";
import { clearErrors } from "../../redux-helpers/Actions/errorAction";
import Spinner from '../Spinner'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function FotgotPassword({ forgotPassword, isLoading }) {
    const classes = useStyles();
    const [email, setEmail] = useState("");

    const handleForgotPassword = async (e) => {
        e.preventDefault();

        // Takes the user parameter and sends it to authAction file.
        forgotPassword({ email });

    };

    return (
        isLoading ? <Spinner /> : (
            <Container component="main" maxWidth="xs" >
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot Password
                    </Typography>
                    <form className={classes.form} onSubmit={handleForgotPassword}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth={true}
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth={true}
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleForgotPassword}
                        >
                            Submit
                        </Button>

                    </form>
                </div>
            </Container >

        )

    );
}

// Please read auth/Register.js file 
const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        error: state.error,
        isLoading: state.auth.isLoading
    };
};

export default connect(mapStateToProps, { forgotPassword, clearErrors })(FotgotPassword);
