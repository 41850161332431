import baseApiReq from '../../api';
import { returnErrors } from "./errorAction";
import {
    GET_DATAFREE_APPS,
    ADD_MOYA_APP,
    EDIT_MOYA_APP,
    DELETE_MOYA_APP,
  //  ADD_OR_EDIT_MOYA_APP_FAIL,
    CLEAR_DFAPPS_STATE,
    APPS_LOADING,
    EDIT_MOYA_APP_IN_REGION
} from "./types";
// import history from "../../history";



// Get Datafree Apps 

export const getDatafreeApps = (dfApps) => async (dispatch) => {
    dispatch({
        type: GET_DATAFREE_APPS,
        payload: dfApps,
    });
}


// SELECTED REGION
export const addMoyaApp = ({ addApp }) => async (dispatch) => {
    dispatch({ type: APPS_LOADING });
    // console.log(addApp)
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let fd = new FormData();
    fd.append("icon", addApp.icon);
    fd.append('name', addApp.name)
    fd.append('url', addApp.url)
    fd.append('moyaKey', addApp.moyaKey)
    fd.append('dfAppId', addApp.dfAppId)
    fd.append('useDarkIcons', addApp.useDarkIcons)
    fd.append('showSplash', addApp.showSplash)
    fd.append('showBanner', addApp.showBanner)
    fd.append('showVideoAd', addApp.showVideoAd)
    fd.append('useGaid', addApp.useGaid)
    fd.append('useLocation', addApp.useLocation)
    fd.append('useStorage', addApp.useStorage)
    fd.append("useDid", addApp.useDid)
    fd.append('sbColor', addApp.sbColour)
    fd.append('abColor', addApp.abColour)
    fd.append('parenticonurl', addApp.parenticonurl)


    await baseApiReq.post("/mdapps", fd, config)
        .then(res => {
          //  console.log("RES FROM AXIOS : ", res)
            dispatch({
                type: ADD_MOYA_APP,
                payload: res.data.data,
            });
            // history.push("/DFapps");
        }).catch(err => {
            // console.log("ERROR addMoyaApp:  ", err)
            dispatch(
                returnErrors(err, 400, "ADD_OR_EDIT_MOYA_APP_FAIL")
            )
        })
};



export const editMoyaApp = ({ editApp, MoyaAppId }) => async (dispatch) => {
    dispatch({ type: APPS_LOADING });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const data = new FormData();
    data.append('icon', editApp.icon)
    data.append('name', editApp.name)
    data.append('moyaKey', editApp.moyaKey)
    data.append('url', editApp.url)
    data.append('dfAppId', editApp.dfAppId)
    data.append('useDarkIcons', editApp.useDarkIcons)
    data.append('showSplash', editApp.showSplash)
    data.append('showBanner', editApp.showBanner)
    data.append('showVideoAd', editApp.showVideoAd)
    data.append('useGaid', editApp.useGaid)
    data.append('useLocation', editApp.useLocation)
    data.append('useStorage', editApp.useStorage)
    data.append("useDid", editApp.useDid)
    data.append('sbColor', editApp.sbColour)
    data.append('abColor', editApp.abColour)


    console.log("MOYA APP ID : ", MoyaAppId)
    await baseApiReq.put(`/mdapps/${MoyaAppId}`, data, config)
        .then(res => {
            console.log("RES FROM AXIOS EDIT ACTION: ", res.data.data)
            dispatch({
                type: EDIT_MOYA_APP,
                payload: res.data.data,
            });
            dispatch({
                type: EDIT_MOYA_APP_IN_REGION,
                payload: {
                    data: res.data.data,
                    message: "dfAppsAction"
                },
            });

        }).catch(err => {
            console.log("ERROR :  ", err)
            dispatch(
                returnErrors("Unable to fetch", 400, "ADD_OR_EDIT_MOYA_APP_FAIL")
            )

        })
}

export const deleteMoyaAppDfApps = ({ MoyaAppID }) => async (dispatch) => {

    await baseApiReq.delete(`/mdapps/${MoyaAppID}`)
        .then(res => {
            // console.log("RES FROM AXIOS : ", res.data)
            dispatch({
                type: DELETE_MOYA_APP,
                payload: MoyaAppID,
            });
        }).catch(err => {
            dispatch(
                returnErrors(err, 400, "DELETE_MOYA_APP_FAIL")
            )
        })
};


export const clearDfAppsState = () => (dispatch) => {
    dispatch({
        type: CLEAR_DFAPPS_STATE,
        payload: null,
    });
}