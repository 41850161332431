import { returnErrors } from "./errorAction";
import {
  USER_LOADING,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from "./types";
import history from "../../history";
// AWS COGNITO
import * as AWS from "aws-sdk/global";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

const {
  REACT_APP_UserPoolId,
  REACT_APP_ClientId,
  REACT_APP_IdentityPoolId,
  REACT_APP_Logins,
} = process.env
//


// Check token and Load User
// once the application starts.
// The loadUser is used in index.js where we declare our store.
export const loadUser = () => (dispatch) => {
  dispatch({ type: USER_LOADING });
  var data = {
    UserPoolId: REACT_APP_UserPoolId,
    ClientId: REACT_APP_ClientId
  };
  var userPool = new CognitoUserPool(data);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser) {
    // console.log("DATA FROM COG : ", cognitoUser)
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        //  console.log("ISSUE WITH SESSION : NO USER ASK THEM TO LOGIN")
        //  console.log("ERROR from cog session : ", err)
        return;
      }
      //  console.log("SESSION : ", session);
      // console.log('session validity: ' + session.isValid());
      cognitoUser.getUserAttributes(function (err, result) {
        if (err) {
          // console.log(err);
          return;
        }
        const userDetails = {
          accessToken: cognitoUser.signInUserSession.accessToken.jwtToken,
          idToken: cognitoUser.signInUserSession.idToken.jwtToken
        }
        // console.log("USER DETAILS : ",userDetails)
        for (let i = 0; i < result.length; i++) {
          userDetails[result[i].getName()] = result[i].getValue()
        }
        //  console.log("---> USER DETAILS ---> : ", userDetails)

        dispatch({
          type: USER_LOADED,
          payload: userDetails,
        });
        dispatch({
          type: LOGIN_SUCCESS,
          payload: userDetails,
        });
        history.push("/DFapps");
      });
    })
  }
  else {
    // console.log("NO USER ASK THEM TO LOGIN")
    dispatch({
      type: LOGIN_FAIL,
    });

  }



};

// Register User
export const register =
  ({ name, email, password }) =>
    (dispatch) => {
      dispatch({ type: USER_LOADING });

      var poolData = {
        UserPoolId: REACT_APP_UserPoolId, // Your user pool id here
        ClientId: REACT_APP_ClientId, // Your client id here
      };
      var userPool = new CognitoUserPool(poolData);
      userPool.signUp(email, password, [], null, (err, data) => {
        if (err) {
          dispatch(
            returnErrors(err, 400, "REGISTER_FAIL")
          );
          dispatch({
            type: REGISTER_FAIL,
          });
        }
        else {
          //  console.log("DATA SIGNUP : ", data)
          const userDetails = {
            accessToken: data.user.storage.accessToken,
            idToken: data.user.storage.idToken,
            user: data.user.username,
            email: data.user.username,
            email_verified: data.userConfirmed,
          }
          dispatch({
            type: REGISTER_SUCCESS,
            payload: userDetails,
          });
          history.push("/DFapps");
        }
      });
    };

// login the user
export const login =
  ({ email, password }) =>
    (dispatch) => {
      dispatch({ type: USER_LOADING });

      var poolData = {
        UserPoolId: REACT_APP_UserPoolId, // Your user pool id here
        ClientId: REACT_APP_ClientId, // Your client id here
      };
      var authenticationData = {
        Username: email,
        Password: password,
      };
      var authenticationDetails = new AuthenticationDetails(authenticationData);
      var userPool = new CognitoUserPool(poolData);
      var userData = {
        Username: email,
        Pool: userPool,
      };
      var cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          var accessToken = result.getAccessToken().getJwtToken();
          /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer*/
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: REACT_APP_IdentityPoolId, // your identity pool id here
            Logins: {
              // Change the key below according to the specific region your user pool is in.
              REACT_APP_Logins: result
                .getIdToken()
                .getJwtToken(),
            },
          });
          var idToken = result.idToken.jwtToken;
          cognitoUser.getUserAttributes(function (err, result) {
            if (err) {
              alert(err.message || JSON.stringify(err));
              return;
            }
            const userDetails = {
              accessToken,
              idToken
            }
            for (let i = 0; i < result.length; i++) {
              // console.log(
              //   "attribute " +
              //   result[i].getName() +
              //   " has value " +
              //   result[i].getValue()
              // );
              userDetails[result[i].getName()] = result[i].getValue()
            }
            dispatch({
              type: LOGIN_SUCCESS,
              payload: userDetails,
            });
            history.push("/DFapps");
          })

        },
        onFailure: function (err) {
          // console.log("Error : ", err);

          dispatch(
            returnErrors(err, 400, "LOGIN_FAIL")
          );
          dispatch({
            type: LOGIN_FAIL,
          });

        },
      });
    };
export const logout = () =>
  (dispatch) => {
    var data = {
      UserPoolId: REACT_APP_UserPoolId,
      ClientId: REACT_APP_ClientId
    };
    var userPool = new CognitoUserPool(data);
    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.signOut();
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    }

  };


export const forgotPassword = ({ email }) =>
  (dispatch) => {
    const poolData = {
      UserPoolId: REACT_APP_UserPoolId, // Your user pool id here
      ClientId: REACT_APP_ClientId // Your client id here
    };
    const userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: email,
      Pool: userPool
    };
    //console.log(userData);
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        //  console.log('call result FORGOT PASSWORD: ' + result);
        history.push("/")
      },
      onFailure: function (err) {
        history.push("/")
        alert(err);
      },
      inputVerificationCode() {
        var verificationCode = prompt('Please input verification code ', '');
        var newPassword = prompt('Enter new password ', '');
        cognitoUser.confirmPassword(verificationCode, newPassword, this);
      }

    });
  }
