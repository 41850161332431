import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import {
    Badge, Dialog, DialogContent, DialogContentText, DialogTitle, 
    DialogActions, Grid, Button, Avatar, TextField, 
} from '@material-ui/core';
import {
    Edit,
} from '@material-ui/icons';

import { updateFolderNameAndSeq, addFolderForRegion } from '../../redux-helpers/Actions/regions'

const FolderAddAndEdit = ({ currentMoyakey, selectedRegionCode, parentFolder, sequence, apporfolderid, dialogTitle, dialogContentText, buttonText, addOrEdit, currentDfAppId, currentIcon, currentUrl, currentName, isOpen, handleClose, updateFolderNameAndSeq, addFolderForRegion }) => {
    const [name, setName] = useState(currentName)
    const [icon, seticon] = useState(null)
    const [iconName, seticonName] = useState(currentIcon)
    const [moyaKey, setmoyaKey] = useState(currentMoyakey !== undefined ? currentMoyakey : '')

    //  const [DFAppId, setDFAppId] = useState(currentDfAppId)
    // const [url, setUrl] = useState(currentUrl)
    // const [anchorEl, setAnchorEl] = useState(null);

    const handleUpload = (event) => {
        // console.log("Upload Icon form multipart")
        var file = event.target.files[0];
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);

        seticon(event.target.files[0])
        //  console.log("ICON : ", icon)
        reader.onloadend = (e) => {
            seticonName([reader.result])
        }
    }

    const handleSubmit = async (event) => {
        //  console.log(addOrEdit)
        event.preventDefault();
        if (addOrEdit === "FolderAdd") {
            //      console.log("ADD A NEW API CALL ")
            // edit action
            //    console.log("EDIT ACTION ")
            let addApp = {
                name,
                icon,
                regionCode: selectedRegionCode[0].regionCode,
                moyaKey
            }

            // console.log("FOLDER ADD : ", addApp)
            addFolderForRegion({ addApp })
            handleClose()
        }
        else if (addOrEdit === "FolderEdit") {
            // edit action
            updateFolderNameAndSeq({ name, icon, parentFolder, sequence, apporfolderid, moyaKey })
            handleClose()
        }

    }
    return (
        <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit}>
                <Grid
                    container
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <DialogTitle id="form-dialog-title">{dialogTitle} </DialogTitle>
                    </Grid>
                    <DialogContent>
                        <DialogContentText>
                            {dialogContentText}
                        </DialogContentText>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <label htmlFor="upload-icon">
                                <label> Icon </label>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="upload-icon"
                                    name="upload-icon"
                                    type="file"
                                    onChange={handleUpload}
                                />

                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    badgeContent={
                                        <Avatar
                                            alt="edit"
                                            sx={{ width: 24, height: 24 }}
                                        >
                                            <Edit />
                                        </Avatar>

                                    }
                                >
                                    <Avatar
                                        style={{ margin: "10%", width: 65, height: 65 }}
                                        alt="Icon"
                                        src={iconName}
                                    />
                                </Badge>
                            </label>

                        </Grid>

                        <Grid item lg={12} xl={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Name"
                                type="name"
                                fullWidth={true}
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <TextField
                                margin="dense"
                                id="appType"
                                value={moyaKey}
                                onChange={(event) => setmoyaKey(event.target.value)}
                                label="Moya Key"
                                type="text"
                                fullWidth={true}

                            />
                        </Grid>

                    </DialogContent>


                    <Grid item xs={12} md={12} lg={12} xl={12}>

                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary">
                                {/* {buttonText} */}
                                Save
                            </Button>
                        </DialogActions>
                    </Grid>

                </Grid>
            </form >
        </Dialog>

    )
}

const mapStateToProps = (state) => {
    return {
        selectedRegionCode: state.region.selectedRegion,
        appsForSelectedRegion: state.region.selectedRegionApps,
        appsInsideFolder: state.region.selectedFolder,
        editedMoyaApp: state.dfApps.app,
        newFolder: state.region.newFolder,
        error: state.error,
        status: state.region.status,
        message: state.region.message
    };

};


export default connect(mapStateToProps, { updateFolderNameAndSeq, addFolderForRegion })(FolderAddAndEdit);
