import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { HexColorPicker } from "react-colorful";

import {
  Popover,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Avatar,
  Grid,
  Badge,
  Checkbox,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import {
  getDatafreeApps,
  addMoyaApp,
  editMoyaApp,
  clearDfAppsState,
} from "../redux-helpers/Actions/dfAppsAction";
import {
  editMoyaAppInRegion,
  addMoyaAppWithNoDFID,
  editMoyaAppInWorkbench,
} from "../redux-helpers/Actions/regions";

const AddAndEditDialog = ({
  app,
  editMoyaAppInRegion,
  apporfolderid,
  contentid,
  dialogTitle,
  dialogContentText,
  buttonText,
  addOrEdit,
  MoyaAppId,
  currentDfAppId,
  currentIcon,
  currentUrl,
  currentName,
  isOpen,
  handleClose,
  addMoyaApp,
  editMoyaApp,
  addMoyaAppWithNoDFID,
  editMoyaAppInWorkbench
}) => {
  const [name, setName] = useState(currentName);
  const [url, setUrl] = useState(currentUrl);
  const [appType, setappType] = useState(
    app.dfapps !== null && app.dfapps !== undefined ? app.dfapps.product : ""
  );
  const [icon, seticon] = useState(null);
  const [DFAppId, setDFAppId] = useState(currentDfAppId);
  const [iconName, seticonName] = useState(currentIcon);
  const [moyaKey, setmoyaKey] = useState(
    app.moyaKey !== null && app.moyaKey !== undefined ? app.moyaKey : ""
  );
  //  const [color, setColor] = useState("#aabbcc");
  const [anchorElSbColour, setAnchorElSbColour] = useState(null);
  const [anchorElAbColour, setAnchorElAbColour] = useState(null);
  const [sbColour, setsbColour] = useState(app.sbColor);
  const [abColour, setabColour] = useState(app.abColor);
  // Checkboxes
  const [useDarkIcons, setUseDarkIcons] = useState(app.useDarkIcons);
  const [showSplash, setshowSplash] = useState(app.showSplash);
  const [showBanner, setshowBanner] = useState(app.showBanner);
  const [showVideoAd, setshowVideoAd] = useState(app.showVideoAd);
  const [useGaid, setuseGaid] = useState(false);
  const [useLocation, setuseLocation] = useState(app.useLocation);
  const [useStorage, setuseStorage] = useState(false);
  const [useDid, setuseDid] = useState(app.useDid);

  // useEffect(() => {
  //     console.log("APP ", app)
  // }, [])

  // Popover Sb colour

  const handleClick = (event) => {
    setAnchorElSbColour(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElSbColour(null);
  };
  const open = Boolean(anchorElSbColour);

  // Popover AB colour
  const handleAbPopoverClick = (event) => {
    setAnchorElAbColour(event.currentTarget);
  };

  const handleAbColourPopoverClose = () => {
    setAnchorElAbColour(null);
  };
  const openAbColour = Boolean(anchorElAbColour);
  // Ends Here

  const handleSubmit = async (event) => {
    //  console.log(addOrEdit)
    event.preventDefault();
    if (addOrEdit === "regionAppEdit") {
      // edit action
      console.log("EDIT ACTION FROM MOBILE VIEW :  ")
      let editApp = {
        contentid,
        apporfolderid,
        name,
        url,
        moyaKey,
        MoyaAppId,
        dfAppId: DFAppId,
        icon,
        useDarkIcons: useDarkIcons ? 1 : 0,
        showSplash: showSplash ? 1 : 0,
        showBanner: showBanner ? 1 : 0,
        showVideoAd: showVideoAd ? 1 : 0,
        useGaid: useGaid ? 1 : 0,
        useLocation: useLocation ? 1 : 0,
        useStorage: useStorage ? 1 : 0,
        useDid: useDid ? 1 : 0,
        sbColour: sbColour === undefined ? "" : sbColour,
        abColour: abColour === undefined ? "" : abColour,
      };
      editMoyaAppInRegion({ editApp, MoyaAppId });
      handleClose();
    } else if (addOrEdit === "moyaAppEdit") {
      // edit action
      console.log("EDIT ACTION ");
      let editApp = {
        name,
        url,
        MoyaAppId,
        dfAppId: DFAppId,
        icon,
        moyaKey,
        useDarkIcons: useDarkIcons ? 1 : 0,
        showSplash: showSplash ? 1 : 0,
        showBanner: showBanner ? 1 : 0,
        showVideoAd: showVideoAd ? 1 : 0,
        useGaid: useGaid ? 1 : 0,
        useLocation: useLocation ? 1 : 0,
        useStorage: useStorage ? 1 : 0,
        useDid: useDid ? 1 : 0,
        sbColour: sbColour === undefined ? "" : sbColour,
        abColour: abColour === undefined ? "" : abColour,
      };
      editMoyaApp({ editApp, MoyaAppId });
      handleClose();
    } else if (addOrEdit === "moyaAppEditInWorkbench") {
      console.log("EDIT IN WORKBENCH");
      let editApp = {
        name,
        url,
        MoyaAppId,
        dfAppId: DFAppId,
        icon,
        moyaKey,
        useDarkIcons: useDarkIcons ? 1 : 0,
        showSplash: showSplash ? 1 : 0,
        showBanner: showBanner ? 1 : 0,
        showVideoAd: showVideoAd ? 1 : 0,
        useGaid: useGaid ? 1 : 0,
        useLocation: useLocation ? 1 : 0,
        useStorage: useStorage ? 1 : 0,
        useDid: useDid ? 1 : 0,
        sbColour: sbColour === undefined ? "" : sbColour,
        abColour: abColour === undefined ? "" : abColour,
      };

      editMoyaAppInWorkbench({ editApp, MoyaAppId });
      handleClose();
    } else if (addOrEdit === "moyaAppWithNoDFID") {
      let addApp = {
        parenticonurl: app.icon_url,
        name,
        url,
        dfAppId: DFAppId,
        icon,
        moyaKey,
        useDarkIcons: useDarkIcons ? 1 : 0,
        showSplash: showSplash ? 1 : 0,
        showBanner: showBanner ? 1 : 0,
        showVideoAd: showVideoAd ? 1 : 0,
        useGaid: useGaid ? 1 : 0,
        useLocation: useLocation ? 1 : 0,
        useStorage: useStorage ? 1 : 0,
        useDid: useDid ? 1 : 0,
        sbColour: sbColour === undefined ? "" : sbColour,
        abColour: abColour === undefined ? "" : abColour,
      };

      // console.log("ICON : ",icon);
      // console.log("APP : ", addApp)
      // console.log(addApp)
      // console.log(app);
      console.log("moyaAppWithNoDFID ACTION ");
      addMoyaAppWithNoDFID({ addApp });
      handleClose();
    } else {
      // add action
      let addApp = {
        parenticonurl: app.icon_url,
        name,
        url,
        dfAppId: DFAppId,
        icon,
        moyaKey,
        useDarkIcons: useDarkIcons ? 1 : 0,
        showSplash: showSplash ? 1 : 0,
        showBanner: showBanner ? 1 : 0,
        showVideoAd: showVideoAd ? 1 : 0,
        useGaid: useGaid ? 1 : 0,
        useLocation: useLocation ? 1 : 0,
        useStorage: useStorage ? 1 : 0,
        useDid: useDid ? 1 : 0,
        sbColour: sbColour === undefined ? "" : sbColour,
        abColour: abColour === undefined ? "" : abColour,
      };

      // console.log("ICON : ",icon);
      // console.log("APP : ", addApp)
      // console.log(addApp)
      // console.log(app);
      console.log("ADD ACTION ");

      addMoyaApp({ addApp });
      handleClose();
    }
  };
  const handleUpload = (event) => {
    // console.log("Upload Icon form multipart")
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);

    seticon(event.target.files[0]);
    //  console.log("ICON : ", icon)
    reader.onloadend = (e) => {
      seticonName([reader.result]);
    };
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <DialogTitle id="form-dialog-title">{dialogTitle} </DialogTitle>
          </Grid>
          <DialogContent>
            <DialogContentText>{dialogContentText}</DialogContentText>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <label htmlFor="upload-icon">
                <label> Icon </label>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-icon"
                  name="upload-icon"
                  type="file"
                  onChange={handleUpload}
                />
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <Avatar alt="edit" sx={{ width: 24, height: 24 }}>
                      <Edit />
                    </Avatar>
                  }
                >
                  <Avatar
                    style={{ margin: "10%", width: 65, height: 65 }}
                    alt="Icon"
                    src={iconName}
                  />
                </Badge>
              </label>
            </Grid>
            <Grid item lg={12} xl={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="name"
                fullWidth={true}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <TextField
                margin="dense"
                id="url"
                value={url}
                onChange={(event) => setUrl(event.target.value)}
                label="url"
                type="text"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <TextField
                margin="dense"
                id="appType"
                value={moyaKey}
                onChange={(event) => setmoyaKey(event.target.value)}
                label="Moya Key"
                type="text"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Grid container>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <TextField
                    margin="dense"
                    id="app_id"
                    value={DFAppId}
                    onChange={(event) => setDFAppId(event.target.value)}
                    label="app_id"
                    type="text"
                    fullWidth={true}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <TextField
                    margin="dense"
                    id="appType"
                    value={appType}
                    //  onChange={(event) => setDFAppId(event.target.value)}
                    label="Type"
                    type="text"
                    fullWidth={true}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box sx={{ display: "flex" }}>
                <FormControl
                  sx={{ m: 2 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      value="Dark Icons"
                      control={
                        <Checkbox
                          checked={useDarkIcons}
                          onChange={(e) => setUseDarkIcons(!useDarkIcons)}
                        />
                      }
                      label="Use Dark Icons"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="Spalsh"
                      control={
                        <Checkbox
                          checked={showSplash}
                          onChange={(e) => setshowSplash(!showSplash)}
                          label="CheckBox"
                        />
                      }
                      label="Show Splash"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  sx={{ m: 2 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      value="Banner"
                      control={
                        <Checkbox
                          checked={showBanner}
                          onChange={(e) => setshowBanner(!showBanner)}
                          label="CheckBox"
                        />
                      }
                      label="Show Banner"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="Videio ID"
                      control={
                        <Checkbox
                          checked={showVideoAd}
                          onChange={(e) => setshowVideoAd(!showVideoAd)}
                          label="CheckBox"
                        />
                      }
                      label="Show Video Ads"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>

                {/* <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                                    <FormGroup> */}
                {/* <FormControlLabel
                                            value="Storage"
                                            control={
                                                <Checkbox
                                                    checked={useStorage}
                                                    onChange={e => setuseStorage(!useStorage)}
                                                    label="CheckBox"
                                                />
                                            }
                                            label="Storage"
                                            labelPlacement="start"
                                        />
                                        <FormControlLabel
                                            value="GA ID"
                                            control={
                                                <Checkbox
                                                    checked={useGaid}
                                                    onChange={e => setuseGaid(!useGaid)}
                                                    label="CheckBox"
                                                />
                                            }
                                            label="GA ID"
                                            labelPlacement="start"
                                        /> */}
                {/* </FormGroup>
                                </FormControl>
                    */}
                <FormControl
                  sx={{ m: 2 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      value="Did"
                      control={
                        <Checkbox
                          checked={useDid}
                          onChange={(e) => setuseDid(!useDid)}
                          label="CheckBox"
                        />
                      }
                      label="Use DID"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="Location"
                      control={
                        <Checkbox
                          checked={useLocation}
                          onChange={(e) => setuseLocation(!useLocation)}
                          label="CheckBox"
                        />
                      }
                      label="Use Location"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FormControl
                sx={{ m: 2 }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <TextField
                    margin="dense"
                    id="sbcolour"
                    value={sbColour || ""}
                    onChange={(event) => setsbColour(event.target.value)}
                    label="Status Bar colour"
                    type="text"
                    fullWidth={true}
                  />
                </FormGroup>
              </FormControl>
              <FormControl
                sx={{ m: 2 }}
                style={{ margin: "4%" }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <Button
                    aria-describedby={"sbcolour"}
                    variant="contained"
                    onClick={handleClick}
                    style={{ backgroundColor: sbColour }}
                  >
                    Status Bar colour
                  </Button>
                  <Popover
                    id={"sbcolour"}
                    open={open}
                    anchorEl={anchorElSbColour}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <HexColorPicker color={sbColour} onChange={setsbColour} />;
                  </Popover>
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FormControl
                sx={{ m: 2 }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <TextField
                    margin="dense"
                    id="abcolour"
                    value={abColour || ""}
                    onChange={(event) => setabColour(event.target.value)}
                    label="Action Bar colour"
                    type="text"
                    fullWidth={true}
                    // disabled
                  />
                </FormGroup>
              </FormControl>
              <FormControl
                sx={{ m: 2 }}
                style={{ margin: "4%" }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <Button
                    aria-describedby={"abcolour"}
                    variant="contained"
                    onClick={handleAbPopoverClick}
                    style={{ backgroundColor: abColour }}
                  >
                    Action Bar colour
                  </Button>
                  <Popover
                    id={"abcolour"}
                    open={openAbColour}
                    anchorEl={anchorElAbColour}
                    onClose={handleAbColourPopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <HexColorPicker color={abColour} onChange={setabColour} />;
                  </Popover>
                </FormGroup>
              </FormControl>
            </Grid>
          </DialogContent>

          <Grid item xs={12} md={12} lg={12} xl={12}>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {buttonText}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    dfApps: state.dfApps.dfApps,
    addApp: state.dfApps,
    isLoading: state.dfApps.isLoading,
    error: state.error,
    status: state.dfApps.status,
    message: state.dfApps.message,
  };
};

export default connect(mapStateToProps, {
  editMoyaAppInWorkbench,
  addMoyaAppWithNoDFID,
  editMoyaAppInRegion,
  getDatafreeApps,
  clearDfAppsState,
  addMoyaApp,
  editMoyaApp,
})(AddAndEditDialog);
