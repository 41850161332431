import baseApiReq from '../../api';
import { returnErrors } from "./errorAction";
import {
  SELECTED_REGION,
  SELECTED_FOLDER,
  SELECTED_SUB_FOLDER,
  ADD_FOLDER_FOR_REGION,
  ADD_MOYA_APP_TO_REGION,
  SELECT_REGION_FOLDER_FAIL,
  COPY_MOYA_APP_TO_FOLDER,
  UPDATE_FOLDER_NAME_IN_REGION,
  EDIT_MOYA_APP_IN_REGION,
  DELETE_MOYA_APP_FROM_REGION,
  DELETE_MOYA_FOLDER_FROM_REGION,
  UPDATE_SEQUENCE_APP_IN_REGION,
  CLEAR_WORKBENCH_STATE,
  GET_MOYA_APPS,
  ADD_MOYA_APP_WITH_NULL_DFID,
  EDIT_MOYA_APP_IN_WORKBENCH,
  GET_ALL_REGIONS,
  // EDIT_MOYA_APP_IN_REGION_FAIL,
  APPS_LOADING,
  JSON_PUBLISHER

} from "./types";


const config = { headers: { "Content-Type": "multipart/form-data" } };

// GET MOYA APPS


export const getAllMoyaApps = () => async (dispatch) => {
  //  console.log("MOYA APPS ")
  dispatch({ type: APPS_LOADING });
  await baseApiReq.get('/mdapps').then(res => {
   // console.log("RESPONSE MDAPPS  : ", res);
    // setMoyaApps([...res.data.data]);
    dispatch({
      type: GET_MOYA_APPS,
      payload: res.data.data,
    });
  }).catch(err => {
    console.log("ERROR : ", err)
    returnErrors(err, 400, "GET_MOYA_APP_FAIL")
  })

};

// Get all regions

export const getAllRegions = () => async (dispatch) => {
  // console.log(addApp)
  await baseApiReq.get("/regions")
      .then(res => {
      //  console.log("RES Regions", res);
          dispatch({
              type: GET_ALL_REGIONS,
              payload: res.data.data,
          });
      }).catch(err => {
         console.log("ERROR unable to fetch regions:  ", err)
          dispatch(
              returnErrors(err, 400, "GET_REGION_FAIL")
          )
      })
};


export const addMoyaAppWithNoDFID = ({ addApp }) => async (dispatch) => {
  // console.log(addApp)
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  let fd = new FormData();
  fd.append("icon", addApp.icon);
  fd.append('name', addApp.name)
  fd.append('url', addApp.url)
  fd.append('moyaKey', addApp.moyaKey)
  fd.append('dfAppId', addApp.dfAppId)
  fd.append('useDarkIcons', addApp.useDarkIcons)
  fd.append('showSplash', addApp.showSplash)
  fd.append('showBanner', addApp.showBanner)
  fd.append('showVideoAd', addApp.showVideoAd)
  fd.append('useGaid', addApp.useGaid)
  fd.append('useLocation', addApp.useLocation)
  fd.append('useStorage', addApp.useStorage)
  fd.append("useDid", addApp.useDid)
  fd.append('sbColor', addApp.sbColour)
  fd.append('abColor', addApp.abColour)
  fd.append('parenticonurl', addApp.parenticonurl)


  await baseApiReq.post("/mdapps", fd, config)
      .then(res => {
        console.log("RES ", res);
          dispatch({
              type: ADD_MOYA_APP_WITH_NULL_DFID,
              payload: res.data.data,
          });
      }).catch(err => {
         console.log("ERROR addMoyaApp:  ", err)
          dispatch(
              returnErrors(err, 400, "ADD_OR_EDIT_MOYA_APP_FAIL")
          )
      })
};

export const editMoyaAppInWorkbench = ({ editApp, MoyaAppId }) => async (dispatch) => {
  // dispatch({ type: APPS_LOADING });

  const config = { headers: { "Content-Type": "multipart/form-data" } };

  const data = new FormData();
  data.append('icon', editApp.icon)
  data.append('name', editApp.name)
  data.append('moyaKey', editApp.moyaKey)
  data.append('url', editApp.url)
  data.append('dfAppId', editApp.dfAppId)
  data.append('useDarkIcons', editApp.useDarkIcons)
  data.append('showSplash', editApp.showSplash)
  data.append('showBanner', editApp.showBanner)
  data.append('showVideoAd', editApp.showVideoAd)
  data.append('useGaid', editApp.useGaid)
  data.append('useLocation', editApp.useLocation)
  data.append('useStorage', editApp.useStorage)
  data.append("useDid", editApp.useDid)
  data.append('sbColor', editApp.sbColour)
  data.append('abColor', editApp.abColour)



  await baseApiReq.put(`/mdapps/${MoyaAppId}`, data, config)
      .then(res => {
        console.log("RES FROM WORKBENCH EDIT : ", res);
          dispatch({
              type: EDIT_MOYA_APP_IN_WORKBENCH,
              payload: res.data.data,
          });
          dispatch({
              type: EDIT_MOYA_APP_IN_REGION,
              payload: {
                  data: res.data.data,
                  message: "dfAppsAction"
              },
          });

      }).catch(err => {
          console.log("ERROR :  ", err)
          dispatch(
              returnErrors("Unable to fetch", 400, "ADD_OR_EDIT_MOYA_APP_FAIL")
          )

      })
}


// SELECTED REGION

export const selectedRegion = ({ region }) =>
  async (dispatch) => {
    console.log("REGION : ", region)
    await baseApiReq.get(`/folders/top?regionCode=${region}`)
      .then(res => {
        //  console.log("RES FROM AXIOS SELECTED REGION : ", res.data.data.length)
        if (res.data.data.length !== 0) {
          dispatch({
            type: SELECTED_REGION,
            payload: res.data.data,
          });
        }
        else {
          dispatch(
            returnErrors('No apps in the regions', 404, "NO_APPS_IN_SELECTED_REGION")
          )
          dispatch({ type: SELECT_REGION_FOLDER_FAIL })
        }


      }).catch(err => {
        //   console.log("ERROR : ", err)
        if (err.response && err.response.status) {
          dispatch(
            returnErrors(err.response.data, err.response.status, "SELECT_REGION_FOLDER_FAIL")
          )
          dispatch({ type: SELECT_REGION_FOLDER_FAIL })
        }
      })
  };

// Add folder in a region 

export const addFolderForRegion = ({ addApp }) => async (dispatch) => {
  // console.log("ADD FOLDER ACTION : ", {
  //   addApp
  // })
  const data = new FormData();
  data.append('icon', addApp.icon)
  data.append('name', addApp.name)
  data.append('regionCode', addApp.regionCode)
  data.append('moyaKey', addApp.moyaKey)
  await baseApiReq.post("/folders/home", data, config)
    .then(res => {
      // console.log("RES FROM AXIOS ADD FOLDER: ", res.data)

      if (res.data.data.length !== 0) {
        dispatch({
          type: ADD_FOLDER_FOR_REGION,
          payload: res.data.data,
        });
      }
    }).catch(err => {
      // console.log("ERROR :  ", err)
      if (err.response && err.response.status) {
        dispatch(
          returnErrors(err.response.data, err.response.status, ADD_FOLDER_FOR_REGION)
        )
      }
    })
};

// FOLDER SELECTED
export const selectedFolder = ({ selectedFolder }) =>
  (dispatch) => {
    dispatch({
      type: SELECTED_FOLDER,
      payload: selectedFolder
    });
  };

// ADD MOYA APP TO REGION

export const addMoyaAppToRegion = ({ folderid, moyaappid }) =>
  async (dispatch) => {
    //  console.log("Add Moya App to FOLDER : ", { folderid, moyaappid })
    await baseApiReq.post("/folderapps/addAppToFolder", { folderid, moyaappid })
      .then(res => {
        //    console.log("RES FROM AXIOS : ", res.data)
        if (res.data.data.length !== 0) {
          dispatch({
            type: ADD_MOYA_APP_TO_REGION,
            payload: res.data.data,
          });
        }
      }).catch(err => {
        //    console.log("ERROR :  ", err.response)
        if (err.response && err.response.status) {
          dispatch(
            returnErrors(err.response.data, err.response.status, ADD_FOLDER_FOR_REGION)
          )

        }
      })
  };

// COPY MOYA APP TO A FOLDER

export const copyMoyaAppToAFolder = ({ newfolderid, contentid }) => async (dispatch) => {
  // console.log("APP TO COPY ", { newfolderid, contentid })

  await baseApiReq.put("/folderapps/moveToSubFolder", { newfolderid, contentid })
    .then(res => {
      //  console.log("RES FROM AXIOS : ", res.data)
      dispatch({
        type: COPY_MOYA_APP_TO_FOLDER,
        payload: res.data.data,
      });
    }).catch(err => {
      //   console.log("ERROR :  ", err.response)
      if (err.response && err.response.status) {
        dispatch(
          returnErrors(err.response.data, err.response.status, ADD_FOLDER_FOR_REGION)
        )
      }
    })
}

// DELETE A MOYA APP FROM FOLDER/REGION

export const deleteMoyaAppFromRegion = ({ contentid }) => async (dispatch) => {
  // console.log("DELETE MOYA APP FROM FOLDER/REGION : ", contentid)
  await baseApiReq.delete(`/folderapps/${contentid}`)
    .then(res => {
      // console.log("RES FROM AXIOS : ", res.data)
      dispatch({
        type: DELETE_MOYA_APP_FROM_REGION,
        payload: contentid,
      });
    }).catch(err => {
      // console.log("ERROR :  ", err)
      dispatch(
        returnErrors(err, 400, "DELETE_APP_FROM_REGION_FAILED")
      )

    })
}

// DELETE A MOYA FOLDER

export const deleteMoyaFolderFromRegion = ({ apporfolderid }) => async (dispatch) => {
  // console.log("DELETE MOYA FOLDER from a region : ", apporfolderid)
  await baseApiReq.delete(`/folders/${apporfolderid}`)
    .then(res => {
      // console.log("RES FROM AXIOS : ", res.data)
      if (res.data.status === "success") {
        dispatch({
          type: DELETE_MOYA_FOLDER_FROM_REGION,
          payload: apporfolderid,
        });
      }
      else {
        dispatch(
          returnErrors("Error while deleting a folder", 400, DELETE_MOYA_FOLDER_FROM_REGION)
        )
      }
    }).catch(err => {
      // console.log("ERROR :  ", err.response)
      if (err.response && err.response.status) {
        dispatch(
          returnErrors(err.response.data, err.response.status, ADD_FOLDER_FOR_REGION)
        )

      }
    })
}

// Get Subfolder contents. 

export const getSubfolderContents = ({ apporfolderid }) =>
  async (dispatch) => {
    //  console.log("REGION : ", apporfolderid)

    await baseApiReq.get(`/folders/?id=${apporfolderid}`)
      .then(res => {
        // console.log("RES FROM AXIOS : ", res.data.data)

        if (res.data.data.length !== 0) {
          dispatch({
            type: SELECTED_SUB_FOLDER,
            payload: res.data.data,
          });
        }
        else {
          dispatch(
            returnErrors('No apps in the regions', 404, "NO_APPS_IN_SELECTED_SUB_FOLDER")
          )
          dispatch({ type: SELECT_REGION_FOLDER_FAIL })
        }


      }).catch(err => {
        // console.log("ERROR : ", err)
        if (err.response && err.response.status) {
          dispatch(
            returnErrors(err.response.data, err.response.status, "SELECT_REGION_FOLDER_FAIL")
          )
          dispatch({ type: SELECT_REGION_FOLDER_FAIL })
        }
      })
  };

// Update folder Name and sequence

export const updateFolderNameAndSeq = ({ name, icon, parentFolder, sequence, apporfolderid, moyaKey }) =>
  async (dispatch) => {
    // console.log("updateFolderNameAndSeq : ", { name, parentFolder, sequence, apporfolderid, icon })
    const data = new FormData();
    data.append('icon', icon)
    data.append('name', name)
    data.append('sequence', sequence)
    data.append('parentFolder', parentFolder)
    data.append('moyaKey', moyaKey)
    await baseApiReq.put(`/folders/${apporfolderid}`, data, config)
      .then(res => {
        console.log("RES FROM AXIOS updateFolderNameAndSeq : ", res.data.data)

        dispatch({
          type: UPDATE_FOLDER_NAME_IN_REGION,
          payload: res.data.data,
        });

      }).catch(err => {
        //  console.log("ERROR : ", err)
        if (err.response && err.response.status) {
          dispatch(
            returnErrors(err.response.data, err.response.status, "SELECT_REGION_FOLDER_FAIL")
          )
          dispatch({ type: SELECT_REGION_FOLDER_FAIL })
        }
      })
  };


// UPDATE_SEQUENCE_APP_IN_REGION

export const updateAppSeqInRegion = ({ contentid, sequence }) =>
  async (dispatch) => {
    //  console.log("updateFolderNameAndSeq : ", { name, parentFolder, sequence, apporfolderid})

    await baseApiReq.put(`folderapps/resequence`, { contentid, sequence })
      .then(res => {
        //  console.log("RES FROM AXIOS update APP Seq : ", res.data.data)

        dispatch({
          type: UPDATE_SEQUENCE_APP_IN_REGION,
          payload: res.data.data,
        });


      }).catch(err => {
        // console.log("ERROR : ", err)
        if (err.response && err.response.status) {
          dispatch(
            returnErrors(err.response.data, err.response.status, "SELECT_REGION_FOLDER_FAIL")
          )
          dispatch({ type: SELECT_REGION_FOLDER_FAIL })
        }
      })
  };

// 
export const editMoyaAppInRegion = ({ editApp, MoyaAppId }) => async (dispatch) => {

  const data = new FormData();
  data.append('icon', editApp.icon)
  data.append('name', editApp.name)
  data.append('url', editApp.url)
  data.append('moyaKey', editApp.moyaKey)
  data.append('apporfolderid', editApp.apporfolderid)
  data.append('contentid', editApp.contentid)
  data.append('dfAppId', editApp.dfAppId)
  data.append('useDarkIcons', editApp.useDarkIcons)
  data.append('showSplash', editApp.showSplash)
  data.append('showBanner', editApp.showBanner)
  data.append('showVideoAd', editApp.showVideoAd)
  data.append('useGaid', editApp.useGaid)
  data.append('useLocation', editApp.useLocation)
  data.append('useStorage', editApp.useStorage)
  data.append("useDid", editApp.useDid)
  data.append('sbColor', editApp.sbColour)
  data.append('abColor', editApp.abColour)


  await baseApiReq.put(`/folderapps/updateMoyaApplication/${MoyaAppId}`, data, config)
    .then(res => {
      console.log("RES FROM AXIOS EDIT ACTION IN MOBILE VIEW: ", res.data.data)
      dispatch({
        type: EDIT_MOYA_APP_IN_REGION,
        // payload: res.data.data,
        payload: {
          data: res.data.data,
          message: "regionsAction",
          MoyaAppId
        },
      });
    }).catch(err => {
      // console.log("ERROR :  ", err)
      if (err.response) {
        dispatch(
          returnErrors(err, 400, "EDIT_MOYA_APP_IN_REGION_FAIL")
        )
      }

    })
}

// clearState
export const clearWorkbenchState = () => (dispatch) => {
  dispatch({
    type: CLEAR_WORKBENCH_STATE,
    payload: null,
  });
}


// JSON Publisher 
export const publishJsonForRegion = ({ regionCode }) =>
  async (dispatch) => {
    //   console.log("REGION : ", region)
    await baseApiReq.post(`/publisher`, { regionCode })
      .then(res => {
        // console.log("RES FROM AXIOS : ", res.data)

        dispatch({
          type: JSON_PUBLISHER,
          payload: res.data.data,
        });


      }).catch(err => {
        // console.log("ERROR FROM JSON_PUBLISHER : ", err)
        dispatch(
          returnErrors('Unable to publish. Please try again later.', 404, "FAILED_TO_PUBLISH_JSON")
        )
      })
  };

