import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from "../Actions/types";

// initial state for the auth component
const initialState = {
  token: localStorage.getItem("accessToken"),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  email: null,
  email_verified: null,
};
// Depending on the Action the state of the application
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        email: action.payload.email,
        email_verified: action.payload.email_verified,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
    //  console.log("Payload : ", action.payload)
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("idToken", action.payload.idToken);
      return {
        ...state,
        token: action.payload.accessToken,
        user: action.payload.email,
        email: action.payload.email,
        email_verified: action.payload.email_verified,
        isAuthenticated: true,
        isLoading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      localStorage.removeItem("accessToken");
      localStorage.removeItem("idToken");
      return {
        ...state,
        token: null,
        user: null,
        email: null,
        email_verified: null,
        isAuthenticated: false,
        isLoading: false,
      };
    default:
      return state;
  }
};
export default authReducer;
