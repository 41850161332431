/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) =>
        String.fromCodePoint(char.charCodeAt(0) + 127397)
      )
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(34px, 20px) scale(1);",
    }
  },
  inputRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
  }
});

function AutocompleteDropdown({ countries, onSelect, selectedRegionCode }) {
  const classes = useStyles();
  const [region, setRegion] = useState(null);
  useEffect(() => {
    // console.log("REGION : ", region);
    if (selectedRegionCode.length !== 0) {
     // console.log(selectedRegionCode[0].md_region.name);
      setRegion(selectedRegionCode[0].md_region.name)
    }
  }, [])
  const defaultProps = {
    options: countries,
    getOptionLabel: (option) => {
      if (option.name === '' && selectedRegionCode.length !== 0) {
        return option.name = region;
      } else {
        return option.name || option
      }
    },
  };
  
  return (
    <Autocomplete
      {...defaultProps}
      className={classes.option}
      classes={{ inputRoot: classes.inputRoot }}
      options={countries}
      id="controlled-demo"
      value={region}
      onChange={(event, newValue) => {
        setRegion(newValue);
        onSelect(newValue);
      }}
      renderOption={(option) => (
        <React.Fragment>
          <span>{countryToFlag(option.code)}</span>
          {option.name} ({option.code}) +{option.phone}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField {...params}
          label="Regions"
          variant="outlined"
        />
      )}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    selectedRegionCode: state.region.selectedRegion,
  };

};

export default connect(mapStateToProps, {})(AutocompleteDropdown);
