import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// Actions
import { login } from "../../redux-helpers/Actions/authAction";
import { clearErrors } from "../../redux-helpers/Actions/errorAction";
import Spinner from '../Spinner'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignIn({ login, error, clearErrors, isAuthenticated, isLoading }) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // console.log("IS LOADING : ", isLoading)
  useEffect(() => {
    // Check for register error
    if (error.id === "LOGIN_FAIL") {
      alert(error.msg);
      clearErrors();
     }
    // if (isAuthenticated) {
    //   console.log("Login Successful Redirect to a new page");
    // }
  }, [error, isAuthenticated, clearErrors]);


  const handleSignIn = async (e) => {
    e.preventDefault();
    // console.log("email : ", email, "password : ", password);
    const user = {
      email,
      password,
    };
    // Takes the user parameter and sends it to authAction file.
    login(user);

  };

  return (
    isLoading ? <Spinner /> : (
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleSignIn}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth={true}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth={true}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSignIn}
            >
              Sign In
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid container justifyContent="space-between">
                <Grid item>
                  {/* <Link to="/" variant="body2" style={{ color: "#f57c00" }}>
                    Register
                  </Link> */}
                </Grid>
                <Grid item>
                  <Link to="/forgotPassword" variant="body2" style={{ color: "#f57c00" }}>
                    Forgot Password
                  </Link>
                </Grid>
              </Grid>

            </Grid>
          </form>
        </div>
      </Container >

    )

  );
}

// Please read auth/Register.js file 
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error,
    isLoading: state.auth.isLoading
  };
};

export default connect(mapStateToProps, { login, clearErrors })(SignIn);
