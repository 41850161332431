import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import regionReducer from "./regionReducer"
import dfAppsReducer from "./dfAppsReducer";
export default combineReducers({
  form: formReducer,
  error: errorReducer,
  auth: authReducer,
  region: regionReducer,
  dfApps: dfAppsReducer,
});
