import {
    ADD_MOYA_APP,
    EDIT_MOYA_APP,
    ADD_OR_EDIT_MOYA_APP_FAIL,
    CLEAR_DFAPPS_STATE,
    APPS_LOADING,
    GET_DATAFREE_APPS,
    DELETE_MOYA_APP
} from "../Actions/types";
import _ from 'lodash';
// initial state for the auth component
const initialState = {
    dfApps: [],
    app: null,
    isLoading: false,
    status: null,
    message: null,
};
// Depending on the Action the state of the application
const dfAppsReducer = (state = initialState, action) => {
    switch (action.type) {
        case APPS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case GET_DATAFREE_APPS:
            return {
                ...state,
                dfApps: action.payload,
                app: null,
                isLoading: false,
            }
        case ADD_MOYA_APP:
            {
                let appIdForNewApp = state.dfApps.findIndex(item => item.app_id === parseInt(action.payload.dfAppId))
                //   console.log("NEW APP ID INDEX : ", appIdForNewApp)
                return {
                    ...state,
                    dfApps: [...state.dfApps, state.dfApps[appIdForNewApp].mdapps.push(action.payload)],
                    app: action.payload,
                    isLoading: false,
                    status: 'success',
                    message: 'succesfully added the apps',
                }
            }
        case EDIT_MOYA_APP: {
            console.log("PAYLOAD : ", typeof(action.payload.dfAppId));
            if (action.payload.dfAppId !== null) {

                let appIdForNewApp = state.dfApps.findIndex(item => item.app_id === action.payload.dfAppId)
                console.log("EDIT APP ID INDEX : ", appIdForNewApp)
                var index = _.findIndex(state.dfApps[appIdForNewApp].mdapps, { id: action.payload.id });
                console.log("EDIT MOYA APP ID INDEX : ", index);
                state.dfApps[appIdForNewApp].mdapps.splice(index, 1, action.payload);
                return {
                    ...state,
                    dfApps: [...state.dfApps],
                    app: action.payload,
                    isLoading: false,
                    status: 'success',
                    message: 'succesfully edited the app',
                }
            } else {
                console.log("ITEM HAS NO DF_APP_ID : ", action.payload)
            }

        }
        case DELETE_MOYA_APP: {
            // let appIdForNewApp = state.dfApps.findIndex(item => item.app_id === parseInt(action.payload.dfAppId))
            let deleteItem = _.filter(state.dfApps,
                {
                    mdapps: [{ id: action.payload }]
                }
            )
            // console.log("ITEM : ", deleteItem[0].app_id)
            let appIdForNewApp = state.dfApps.findIndex(item => item.app_id === parseInt(deleteItem[0].app_id))
            // console.log("DELETE DF APP ID INDEX : ", appIdForNewApp)
            _.remove(state.dfApps[appIdForNewApp].mdapps, { id: action.payload })
            // console.log("ACTION PAYLOAD DELETE: ",action.payload)
            return {
                ...state,
                status: 'success',
                message: 'succesfully deleted the app',
            }

        }
        case ADD_OR_EDIT_MOYA_APP_FAIL:
            return {
                ...state,
                app: null,
                isLoading: false
            };
        case CLEAR_DFAPPS_STATE:
            return {
                ...state,
                isLoading: false,
                status: null,
                message: null,
            };
        default:
            return state
    }
}

export default dfAppsReducer;