import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import reducers from "./redux-helpers/Reducers/index";
import { loadUser } from "./redux-helpers/Actions/authAction";

// Compose is a part of redux which can apply multiple store enhancers.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Create the store for the application
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
// Store.dispatch dispatches an action loadUser()
// this action checks the token in the local storage and autosigns in the user
store.dispatch(loadUser());
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
