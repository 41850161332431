import './App.css';
import Routes from './routes/index';
import { Router } from 'react-router-dom';
import history from './history';
import { grey } from '@material-ui/core/colors';

import {
  ThemeProvider,
  createTheme,
  CssBaseline,
} from "@material-ui/core";


const theme = createTheme({
  palette: {
     type: "dark",
    primary: {
    //  main: "#f57c00",
    main:"rgb(104, 142, 255)" 
    // main : "#BB86FC"
      // main: "#3700B3"
    },
  //   secondary: {
  //   //  main: "#B00020",
  //   //  main: "#03DAC5"
  //     main:"#018786"
  // },

  // primary: deepOrange,
  // divider: deepOrange[700],
  background: {
    default: "rgb(34, 43, 54)",
    paper: "rgb(34, 43, 54)",
  },
  text: {
    primary: '#fff',
    secondary: grey[500],
  },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        // backgroundColor: "dark",
        backgroundColor:"rgb(34, 43, 54)",
        color:"white"
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*': {
          'scrollbar-width': 'thin',
          },
        '*::-webkit-scrollbar': {
          width: '3px',
          height: '2px',
          borderRadius:"10px"
        },
        '*::-webkit-scrollbar-thumb':{
          backgroundColor:"grey",
          paddingTop:"25rem"
          
        },
        '*::-webkit-scrollbar-thumb:hover':{
          backgroundColor:"#f57c00"
        }
      },
   },   
  },

});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='App'>
        {/* <DFapps /> */}
        {/* <MoyaWorkbench />  */}
        <Router history={history}>
          <Routes />
        </Router>

      </div>
    </ThemeProvider>
  );
}
export default App;
