import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import {
    makeStyles, Menu, MenuItem, 
     Avatar, TextField, List, ListItem, IconButton,
} from '@material-ui/core';
import { toast } from "react-toastify";
import { clearErrors } from "../../redux-helpers/Actions/errorAction";
import {
    deleteMoyaAppFromRegion,
    clearWorkbenchState, deleteMoyaFolderFromRegion, updateFolderNameAndSeq, getSubfolderContents, selectedFolder,
    copyMoyaAppToAFolder, selectedRegion, updateAppSeqInRegion,
    editMoyaAppInRegion
} from '../../redux-helpers/Actions/regions';
import AddAndEditDialog from '../AddAndEditDialog';
import FolderAddAndEdit from './FolderAddAndEdit';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position: "relative",
    },
    iphone: {
        position: "relative",
        margin: "40px auto",
        width: "390px",
        height: "680px",
        maxHeight: "80%",
        borderRadius: "40px",
        backgroundColor: "#f2f2f2",
        boxShadow:
            "0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111",

        "&:before": {
            content: "''",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            top: "0px",
            width: "56%",
            height: "30px",
            backgroundColor: "#1f1f1f",
            borderRadius: "0px 0px 40px 40px",
        },
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(1),
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',


    },
    listPaper: {
        overflowY: 'auto',
        overflowX: 'hidden',
        margin: "40px auto",
        width: "400px",
        height: "600px",
        borderRadius: "40px",
    },
    listItem: {
        flex: '0 0 25%',
    },
    multilineColor: {
        color: "black",
        textAlign: 'center',
        overflow: 'hidden'

    }
}));


const Mobileview = ({ editMoyaAppInRegion, deleteMoyaAppFromRegion, updateAppSeqInRegion, editedMoyaApp, clearWorkbenchState, status, message, deleteMoyaFolderFromRegion, updateFolderNameAndSeq, selectedRegion, getSubfolderContents, deleteMoyaApp, copyMoyaAppToAFolder, selectedRegionCode, appsForSelectedRegion, selectedFolder, appsInsideFolder, error, clearErrors }) => {
    const classes = useStyles();
    const [apps, setApps] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    //Edit folder dialog
    const [dialogForEditFolder, setdialogForEditFolder] = useState(false)
    // drag app
    const [draggAppToFolder, setDraggAppToFolder] = useState(null);
    const [deleteAnAppOrFolder, setdeleteAnAppOrFolder] = useState(null)
    //
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContentText, setDialogContentText] = useState('')
    const [buttonText, setButtonText] = useState('')

    const [addOrEdit, setaddOrEdit] = useState('')
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [icon, seticon] = useState('')
    const [DFAppId, setDFAppId] = useState('')
    const [MoyaAppId, setMoyaAppId] = useState('')
    const [contentID, setcontentID] = useState('')
    const [apporfolderid, setapporfolderid] = useState('')

    const [moyaKey, setmoyaKey] = useState('')

    useEffect(() => {
        // console.log("selectedRegionCode.name", selectedRegionCode)
        if (error.id === "SELECT_REGION_FOLDER_FAIL") {
            setApps([])
            // console.log("ERROR FROM SELECT : ", error)
            toast.error("Unable to fetch")
            clearErrors();
        } else if (error.id === "NO_APPS_IN_SELECTED_REGION") {
            setApps([])
            toast.error("No apps found for this region")
            clearErrors();
        }
        else if (error.id === "EDIT_MOYA_APP_IN_REGION_FAIL") {
            toast.error("failed to edit the app in region")
            clearErrors();
        }
        else {
            //  console.log("USE EFFECT RUN ")
            setApps([...appsForSelectedRegion])
            setdeleteAnAppOrFolder(null);

        }
        if (status !== null && message !== null) {
            // setApps([...appsForSelectedRegion])
            setdeleteAnAppOrFolder(null);
            toast.success(message);
            // setApps([...appsForSelectedRegion])
            clearWorkbenchState();
        }
        // console.log("APPS FOR MOBILE VIEW : ", apps.length)

    }, [error, appsForSelectedRegion, appsInsideFolder, selectedRegionCode, status, message, editedMoyaApp]);

    // Update Item Name
    const handleInputChange = (e, index) => {
        const { value } = e.target;
        // console.log({ value, index });
        const list = [...apps];
        list[index].name = value;
        setApps(list);
    };
    // const handleBlur = (e, item) => {
    //     e.preventDefault();
    //    // console.log("Blur event : ", item)
    //     let { name, parentFolder, sequence, apporfolderid } = item

    //     if (item.type === "FOLDER") {
    //         updateFolderNameAndSeq({ name, parentFolder, sequence, apporfolderid })
    //     }
    //     else {
    //         // console.log("APP : ", item)   
    //         //editMoyaAppInRegion({ editApp, MoyaAppId })
    //     }
    // }
    // Ends Here

    // Sub folder click and back button click
    const onFolderButtonClick = (item) => {
        //  console.log(item.apporfolderid);
        getSubfolderContents({ apporfolderid: item.apporfolderid })
    };

    // Context menu (Right click for delete )
    const _onContextMenu = (e, item) => {
        //  console.log(e.type);
        if (e.type === "click") {
            //  console.log("Left click");
            setAnchorEl(null);
            setdeleteAnAppOrFolder(null)
        } else if (e.type === "contextmenu") {
            // console.log("Right click");
            handleMenuClick(e, item);
            setdeleteAnAppOrFolder(item)
            // console.log(item)

        }
    }
    const handleMenuClick = (event, item) => {
        event.preventDefault();
        //   console.log("EVENT", event.currentTarget);
        // console.log("ITEM FROM CONTEXT : ", item)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Delete function 
    const handleDelete = (e) => {
        e.preventDefault();
        //  console.log("DELETE : ", deleteAnAppOrFolder)
        if (deleteAnAppOrFolder.type === 'FOLDER') {
            deleteMoyaFolderFromRegion({ apporfolderid: deleteAnAppOrFolder.apporfolderid })
            setAnchorEl(null);
        }
        else {
            deleteMoyaAppFromRegion({ contentid: deleteAnAppOrFolder.contentid })
            setAnchorEl(null);
        }
    };
    // Ends here
    // Handle Edit
    const handleClickOpen = (e, item, moyaAppEdit) => {
        e.preventDefault();
        if (deleteAnAppOrFolder.type === "FOLDER") {
            //   console.log("FOLDER DIALOG")
            setapporfolderid(deleteAnAppOrFolder.apporfolderid)
            setName(deleteAnAppOrFolder.name)
            seticon(deleteAnAppOrFolder.icon)
            setmoyaKey(deleteAnAppOrFolder.moyaKey)
            setdialogForEditFolder(true)
            setAnchorEl(null);

        }
        else {
            if (moyaAppEdit === "moyaAppEdit") {
                //  console.log("EDIT DELETE CONTEXT : ", deleteAnAppOrFolder)
                // console.log("ITEM EDIT : ", MoyaAppId, DFAppId, name, icon, url, contentID)
                setaddOrEdit("moyaAppEdit")
                setMoyaAppId(deleteAnAppOrFolder.apporfolderid)
                setDFAppId(deleteAnAppOrFolder.dfappid)
                setName(deleteAnAppOrFolder.name)
                seticon(deleteAnAppOrFolder.icon)
                setUrl(deleteAnAppOrFolder.url)
                setcontentID(deleteAnAppOrFolder.contentid)
                setapporfolderid(deleteAnAppOrFolder.apporfolderid)

                setDialogTitle("Edit Datafree app to Moya ")
                setDialogContentText("Edit your DF app to moya discover.")
                setButtonText('Save')
                setDialogOpen(true)
                setAnchorEl(null);
            }
        }

    };

    // Edit the app 

    const handleDialogClose = (e) => {
        //  e.preventDefault();
        setDialogOpen(false)
    };

    //Ends here

    // Handle Drag to a folder
    //  Drag apps to folders
    const handleDrag = (e, item) => {
        // e.preventDefault();
        // e.stopPropagation();
        // console.log("Drag sterted : APP : ", item);
        setDraggAppToFolder(item);
    };
    const handleDragEnter = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        //  console.log("Drag Entered the folder space : ", item);
    };
    const handleDragLeave = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        //  console.log("Drag Left the folder space : ", item);
    };
    const handleDragOver = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        // console.log("Drag Over : ", item);
    };
    const handleDrop = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
        // console.log("FOLDER : ", item, "APP : ", draggAppToFolder)

        if (draggAppToFolder.type === "APP" && item.type === "APP") {
            // console.log("CHANGE SEQ  of APPS : ", "ITEM MOVED : ", draggAppToFolder, "OVER : ", item)
            // console.log("NEW SEQ CALL : ", { contentid: draggAppToFolder.contentid, sequence: item.sequence + 1 })
            if (draggAppToFolder.sequence < item.sequence) {
                updateAppSeqInRegion({ contentid: draggAppToFolder.contentid, sequence: item.sequence + 1 })
            } else {
                updateAppSeqInRegion({ contentid: draggAppToFolder.contentid, sequence: item.sequence })
            }
        }
        else if (draggAppToFolder.type === "FOLDER" && item.type === "FOLDER") {
            // console.log("CHANGE SEQ  of Folders : ", "ITEM Dragged : ", draggAppToFolder, "OVER : ", item)
            const { name, parentFolder, apporfolderid } = draggAppToFolder
            const { sequence } = item
            // console.log("Data for axios call for update folder Seq : ", { name, parentFolder, sequence: sequence + 1, apporfolderid })
            //  updateFolderNameAndSeq({ name, parentFolder, sequence: sequence + 1, apporfolderid })
            if (draggAppToFolder.sequence < item.sequence) {
                updateFolderNameAndSeq({ name, parentFolder, sequence: sequence + 1, apporfolderid })
            } else {
                updateFolderNameAndSeq({ name, parentFolder, sequence: sequence, apporfolderid })
            }
        }
        else {
            // console.log("FOLDER : ", item, "APP : ", draggAppToFolder)
            // contentid   ----- is for the app that you are moving
            // newfolderid ----- id for the folder
            copyMoyaAppToAFolder({ newfolderid: item.apporfolderid, contentid: draggAppToFolder.contentid })
        }

    };
    // Ends here


    // Close FOlder Dialog 
    const handleFolderDialogClose = (e) => {
        //  e.preventDefault();
        setdialogForEditFolder(false)
    };
    return (

        <div className={classes.root}>
            <div className={classes.iphone}>
                <div className={classes.listPaper}>
                    <List className={classes.listContainer}>
                        {apps.map((item, index) => {
                            return (
                                <ListItem key={index} className={classes.listItem}
                                    onContextMenu={(e) => {
                                        _onContextMenu(e, item);
                                    }}
                                >
                                    <div>
                                        {/* Context menu (Right click) Can use React Dropzone to delete */}
                                        <div>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={(e) => handleDelete(e, item)}>
                                                    Delete
                                                </MenuItem>
                                                <MenuItem onClick={e => handleClickOpen(e, item, "moyaAppEdit")}>
                                                    Edit
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                        {/* Context menu Ends here */}

                                        {item.type === 'FOLDER' ? (
                                            <div>
                                                <IconButton
                                                    aria-label='folder'
                                                    color='primary'
                                                    draggable="true"
                                                    onDragStart={(e) => handleDrag(e, item)}
                                                    onDragLeave={(e) => handleDragLeave(e, item)}
                                                    onDragOver={(e) => handleDragOver(e, item)}
                                                    onDragEnter={(e) => handleDragEnter(e, item)}
                                                    onDrop={(e) => handleDrop(e, item)}
                                                    onClick={() => onFolderButtonClick(item)}
                                                >
                                                    <Avatar src={item.icon} >
                                                        {/* <Folder color="primary" /> */}

                                                    </Avatar>

                                                </IconButton>
                                                <TextField
                                                    value={item.name}
                                                    multiline
                                                    maxRows={2}
                                                    minRows={2}
                                                    //maxRows={2}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            input: classes.multilineColor
                                                        }
                                                    }}
                                                    //    onBlur={(e) => handleBlur(e, item)}
                                                    disabled
                                                />
                                            </div>
                                        ) : (
                                            <div>
                                                <IconButton
                                                    aria-label="APP"
                                                    draggable="true"
                                                    onDragStart={(e) => handleDrag(e, item)}
                                                    onDragLeave={(e) => handleDragLeave(e, item)}
                                                    onDragOver={(e) => handleDragOver(e, item)}
                                                    onDragEnter={(e) => handleDragEnter(e, item)}
                                                    onDrop={(e) => handleDrop(e, item)}
                                                >
                                                    <Avatar
                                                        src={item.icon}
                                                    >
                                                    </Avatar>
                                                </IconButton>
                                                <TextField
                                                    multiline
                                                    maxRows={2}
                                                    minRows={2}
                                                    value={item.name}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.multilineColor
                                                        }
                                                    }}
                                                    //     onBlur={(e) => handleBlur(e, item)}
                                                    disabled
                                                />
                                            </div>
                                        )}
                                    </div>
                                </ListItem>
                            );
                        })}
                    </List>
                </div>

            </div>
            {/* Dialog for App edit */}
            {
                dialogOpen ? (
                    <AddAndEditDialog
                        app={deleteAnAppOrFolder}
                        isOpen={dialogOpen} handleClose={handleDialogClose}
                        currentName={name} currentUrl={url} currentIcon={icon}
                        currentDfAppId={DFAppId} apporfolderid={apporfolderid}
                        dialogTitle={dialogTitle} dialogContentText={dialogContentText} buttonText={buttonText} addOrEdit={"regionAppEdit"} MoyaAppId={MoyaAppId} contentid={contentID} />
                ) : null
            }
            {
                dialogForEditFolder ? (
                    <FolderAddAndEdit isOpen={dialogForEditFolder} handleClose={handleFolderDialogClose}
                        currentName={name} currentUrl={url} currentIcon={icon}
                        parentFolder={deleteAnAppOrFolder.parentFolder} sequence={deleteAnAppOrFolder.sequence} apporfolderid={apporfolderid}
                        currentMoyakey={moyaKey}
                        // name: folderName, parentFolder, sequence, apporfolderid
                        dialogTitle="Edit Folder" dialogContentText="Edit your folder" buttonText="Edit" addOrEdit="FolderEdit" MoyaAppId={MoyaAppId} />
                ) : null
            }
            {/*  Ends here */}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        selectedRegionCode: state.region.selectedRegion,
        appsForSelectedRegion: state.region.selectedRegionApps,
        appsInsideFolder: state.region.selectedFolder,
        editedMoyaApp: state.dfApps.app,
        newFolder: state.region.newFolder,
        error: state.error,
        status: state.region.status,
        message: state.region.message
    };

};
export default connect(mapStateToProps, { editMoyaAppInRegion, updateAppSeqInRegion, clearWorkbenchState, deleteMoyaFolderFromRegion, updateFolderNameAndSeq, selectedRegion, getSubfolderContents, deleteMoyaAppFromRegion, copyMoyaAppToAFolder, selectedFolder, clearErrors })(Mobileview);
