import {
  SELECTED_REGION,
  SELECTED_SUB_FOLDER,
  SELECT_REGION_FOLDER_FAIL,
  ADD_FOLDER_FOR_REGION,
  ADD_MOYA_APP_TO_REGION,
  COPY_MOYA_APP_TO_FOLDER,
  UPDATE_FOLDER_NAME_IN_REGION,
  DELETE_MOYA_APP_FROM_REGION,
  DELETE_MOYA_FOLDER_FROM_REGION,
  CLEAR_WORKBENCH_STATE,
  UPDATE_SEQUENCE_APP_IN_REGION,
  EDIT_MOYA_APP_IN_REGION,
  EDIT_MOYA_APP_IN_REGION_FAIL,
  JSON_PUBLISHER,
  GET_MOYA_APPS,
  ADD_MOYA_APP_WITH_NULL_DFID,
  EDIT_MOYA_APP_IN_WORKBENCH,
  GET_ALL_REGIONS,
  APPS_LOADING,
  ADD_MOYA_APP,
} from "../Actions/types";
import _ from "lodash";
// import { selectedRegion } from "../Actions/regions";
// initial state for the auth component
const initialState = {
  moyaApps: [],
  regions: [],
  selectedRegion: [],
  selectedRegionApps: [],
  selectedFolder: null,
  newFolder: null,
  status: null,
  message: null,
  app: null,
  isLoading: false,
};
// Depending on the Action the state of the application
const regionReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case GET_MOYA_APPS:
      return {
        ...state,
        moyaApps: action.payload,
      };
    case GET_ALL_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };
    case ADD_MOYA_APP_WITH_NULL_DFID:
      return {
        ...state,
        moyaApps: [action.payload, ...state.moyaApps],
        app: action.payload,
        status: "Success",
        message: "Successfully added the app.",
      };
    case EDIT_MOYA_APP_IN_WORKBENCH:
      // console.log("EDIT MOYA APP ID INDEX : ", action.payload);
      var index = _.findIndex(state.moyaApps, { id: action.payload.id });
      state.moyaApps.splice(index, 1, action.payload);
      return {
        ...state,
        moyaApps: [...state.moyaApps],
        app: action.payload,
        status: "Success",
        message: "Successfully edited the app.",
      };
    case SELECTED_REGION:
      //   // console.log("ACTION PAYLOAD", action.payload)
      return {
        ...state,
        selectedRegion: action.payload,
        selectedRegionApps: action.payload[0].mdfoldercontents,
      };
    case SELECTED_SUB_FOLDER:
      return {
        ...state,
        selectedRegion: action.payload,
        selectedRegionApps: action.payload[0].mdfoldercontents,
      };
    case ADD_FOLDER_FOR_REGION:
      return {
        ...state,
        selectedRegion: action.payload,
        selectedRegionApps: action.payload[0].mdfoldercontents,
        newFolder: action.payload,
        status: "success",
        message: "succesfully added a new folder",
      };
    case SELECT_REGION_FOLDER_FAIL:
      return {
        ...state,
        selectedRegion: [],
        selectedRegionApps: [],
        selectedFolder: null,
      };
    case ADD_MOYA_APP_TO_REGION:
      return {
        ...state,
        selectedRegion: action.payload,
        selectedRegionApps: action.payload[0].mdfoldercontents,
        status: "success",
        message: "succesfully added Moya app to your region.",
      };
    case COPY_MOYA_APP_TO_FOLDER:
      //   // console.log("COPY TO FOLDER : ", action.paylod)
      return {
        ...state,
        selectedRegion: action.payload,
        selectedRegionApps: action.payload[0].mdfoldercontents,
        status: "success",
        message: "succesfully added the app to a folder.",
      };
    case DELETE_MOYA_APP_FROM_REGION: {
      //// console.log(state.selectedRegionApps.length)
      _.remove(
        state.selectedRegionApps,
        (app) => app.contentid === action.payload
      );
      // console.log(state.selectedRegionApps.length)
      return {
        ...state,
        selectedRegionApps: [...state.selectedRegionApps],
        status: "success",
        message: "succesfully deleted the moya app from region.",
      };
    }
    case DELETE_MOYA_FOLDER_FROM_REGION: {
      _.remove(
        state.selectedRegionApps,
        (app) => app.apporfolderid === action.payload
      );
      //  // console.log({ ...state })
      return {
        ...state,
        selectedRegionApps: [...state.selectedRegionApps],
        status: "success",
        message: "succesfully deleted the folder from region.",
      };
    }
    case UPDATE_FOLDER_NAME_IN_REGION: {
      return {
        ...state,
        selectedRegion: action.payload,
        selectedRegionApps: action.payload[0].mdfoldercontents,
        status: "success",
        message: "succesfully updated the folder for region.",
      };
    }
    case UPDATE_SEQUENCE_APP_IN_REGION:
      return {
        ...state,
        selectedRegion: action.payload,
        selectedRegionApps: action.payload[0].mdfoldercontents,
        status: "success",
        message: "succesfully updated the App sequence for region.",
      };
    case EDIT_MOYA_APP_IN_REGION:
      // console.log("Action.payload : ", action.payload)
      // var index = _.findIndex(state.selectedRegionApps, { apporfolderid: action.payload.id });
      // console.log("INDEX : ", index)
      // if (index !== -1) {
      let x = _.filter(state.selectedRegionApps, function (o) {
        return o.apporfolderid === action.payload.data.id && o.type === "APP";
      });
      // console.log("X : ", x.length);
      if (x.length !== 0 && action.payload.message === "dfAppsAction") {
        // console.log("APPS IN MOBILEVIEW ", x)
        // console.log("Payload : ", action.payload.data);
        const arr = [];
        x.map((item) => {
          arr.push({
            apporfolderid: item.apporfolderid,
            contentid: item.contentid,
            dfappid: item.dfappid,
            parentFolder: item.parentFolder,
            product: item.product,
            sequence: item.sequence,
            type: item.type,
            ...action.payload.data,
          });
        });
        //  // console.log("NEW ARRAY : ", arr)
        var res = state.selectedRegionApps.map(
          (obj) => arr.find((o) => o.contentid === obj.contentid) || obj
        );
        //// console.log("RES : ", res)
        state.selectedRegionApps.mdfoldercontents = res;
        return {
          ...state,
          selectedRegionApps: res,
          status: "success",
          message: "succesfully updated the App for region.",
        };
      } else {
        // console.log("Running else loop : ")
        let x = _.filter(action.payload.data[0].mdfoldercontents, function (o) {
          return o.apporfolderid === action.payload.MoyaAppId;
        });
        // console.log("X FROM ELSE ", x);
        const lastIndex = state.moyaApps.findIndex(
          (app) => app.id === action.payload.MoyaAppId
        )
        if (lastIndex !== -1) {
          let dfapps = state.moyaApps[lastIndex].dfapps;
          let dfAppId = state.moyaApps[lastIndex].dfAppId;
          // console.log("APP FROM STORE :: ", state.moyaApps[lastIndex])
          state.moyaApps[lastIndex] = {
            id: x[0].apporfolderid,
            name: x[0].name,
            icon: x[0].icon,
            url: x[0].url,
            moyaKey: x[0].moyaKey,
            abColor: x[0].abColor,
            sbColor: x[0].sbColor,
            useDarkIcons: x[0].useDarkIcons,
            showSplash: x[0].showSplash,
            showBanner: x[0].showBanner,
            showVideoAd: x[0].showVideoAd,
            useGaid: x[0].useGaid,
            useLocation: x[0].useLocation,
            useStorage: x[0].useStorage,
            useDid: x[0].useDid,
            dfapps,
            dfAppId
          };
          // console.log("state.moyaApps[lastIndex] : ", state.moyaApps[lastIndex])
          // console.log("  dfapps, dfAppId : ", { dfapps, dfAppId })

        }

        return {
          ...state,
          moyaApps: [...state.moyaApps],
          selectedRegion: action.payload.data,
          selectedRegionApps: action.payload.data[0].mdfoldercontents,
          status: "success",
          message: "succesfully updated the App for region.",
        };
      }
    // }

    case EDIT_MOYA_APP_IN_REGION_FAIL:
      return {
        ...state,
        status: "fail",
        message: "failed to edit the app in region",
      };
    case CLEAR_WORKBENCH_STATE:
      return {
        ...state,
        app: null,
        status: null,
        message: null,
      };
    case JSON_PUBLISHER:
      return {
        ...state,
        status: "success",
        message: "succesfully pushed the new JSON.",
      };
    default:
      return state;
  }
};

export default regionReducer;
