// types are used to make it consistent across actions and reducers.

export const USER_LOADING = "USER_LOADING"
export const USER_LOADED = "USER_LOADED"
export const AUTH_ERROR = "AUTH_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const GET_ERRORS = "GET_ERRORS"
export const CLEAR_ERRORS = "CLEAR_ERRORS"


// Datafree apps page
export const GET_DATAFREE_APPS = "GET_DATAFREE_APPS"


// Regions 
export const SELECTED_REGION = "SELECTED_REGION"
export const SELECTED_FOLDER = "SELECTED_FOLDER"
export const SELECTED_SUB_FOLDER = "SELECTED_SUB_FOLDER"
export const UPDATE_FOLDER_NAME_IN_REGION = "UPDATE_FOLDER_NAME_IN_REGION"

export const UPDATE_SEQUENCE_APP_IN_REGION = "UPDATE_SEQUENCE_APP_IN_REGION"


export const ADD_FOLDER_FOR_REGION = "ADD_FOLDER_FOR_REGION"
export const ADD_MOYA_APP_TO_REGION = "ADD_MOYA_APP_TO_REGION"

export const COPY_MOYA_APP_TO_FOLDER = "COPY_MOYA_APP_TO_FOLDER"

export const SELECT_REGION_FOLDER_FAIL = "SELECT_REGION_FOLDER_FAIL"

export const CLEAR_WORKBENCH_STATE = "CLEAR_WORKBENCH_STATE"
// Add or Edit Moya apps from df Apps
export const ADD_MOYA_APP = "ADD_MOYA_APP"
export const EDIT_MOYA_APP = "EDIT_MOYA_APP"
export const EDIT_MOYA_APP_IN_WORKBENCH = "EDIT_MOYA_APP_IN_WORKBENCH"
export const DELETE_MOYA_APP = "DELETE_MOYA_APP"

export const EDIT_MOYA_APP_IN_REGION = "EDIT_MOYA_APP_IN_REGION"
export const EDIT_MOYA_APP_IN_REGION_FAIL = "EDIT_MOYA_APP_IN_REGION_FAIL"
export const APPS_LOADING = "APPS_LOADING"
export const DELETE_MOYA_APP_FROM_REGION = "DELETE_MOYA_APP_FROM_REGION"
export const DELETE_MOYA_FOLDER_FROM_REGION = "DELETE_MOYA_FOLDER_FROM_REGION"

export const ADD_OR_EDIT_MOYA_APP_FAIL = "ADD_MOYA_APP"
export const CLEAR_DFAPPS_STATE = "CLEAR_DFAPPS_STATE"


// GET ALL MOYA APPS

export const GET_ALL_REGIONS = "GET_ALL_REGIONS"
export const GET_MOYA_APPS = "GET_MOYA_APPS"
export const ADD_MOYA_APP_WITH_NULL_DFID = "ADD_MOYA_APP_WITH_NULL_DFID"


// PUBLISH JSON
export const JSON_PUBLISHER = "JSON_PUBLISHER"