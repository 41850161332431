import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Grid,
  makeStyles,
  Paper,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Avatar,
  Button,
  ButtonGroup,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import Spinner from "../Spinner";
import {
  addMoyaAppToRegion,
  addFolderForRegion,
  selectedRegion,
  publishJsonForRegion,
  getAllMoyaApps,
  getAllRegions,
  clearWorkbenchState,
} from "../../redux-helpers/Actions/regions";
import { clearDfAppsState } from "../../redux-helpers/Actions/dfAppsAction";
import { clearErrors } from "../../redux-helpers/Actions/errorAction";


import AddAndEditDialog from "../AddAndEditDialog";

import {
  Search,
  Close,
  ArrowRightAltOutlined,
  Edit,
  Folder,
  ArrowBackIos,
  Publish,
  Add,
} from "@material-ui/icons";
import Fuse from "fuse.js";
import { toast } from "react-toastify";

import AutocompleteDropdown from "../AutocompleteDropdown";
import Mobileview from "./Mobileview";
import FolderAddAndEdit from "./FolderAddAndEdit";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    flexGrow: 1,
    overflow: "hidden",
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 50,
    },

    width: "60%",
    marginBottom: "2%",
    justifyContent: "centre",
    paddingTop: "0px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  content: {
    width: "100%",
    minHeight: "300",
    overflowY: "auto",
    overflowX: "hidden",
  },
  listPaper: {
    marginRight: "10%",
    margin: "2%",
    padding: theme.spacing(2),
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    height: 710,
    maxHeight: "80%",
  },
  button: {
    borderRadius: "5em",
  },
  publishButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
  },
  newFolderButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  list: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
    maxWidth: "100%",
  },
}));

function MoyaWorkbench({
  countries,
  addAppWithNoDFIDStore,
  individualMoyaApps,
  getAllRegions,
  getAllMoyaApps,
  publishJsonForRegion,
  editedMoyaApp,
  addMoyaAppToRegion,
  selectedRegion,
  selectedRegionCode,
  error,
  status,
  message,
  clearDfAppsState,
  clearErrors,
  addFolderForRegion,
}) {
  // get all data
  console.log("individualMoyaApps : ", individualMoyaApps.length);
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [moyaApps, setMoyaApps] = useState([]);
  const [moyaAppsIcons, setmoyaAppsIcons] = useState([]);
  const [regions, setRegions] = useState([]);
  const [value, setValue] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContentText, setDialogContentText] = useState("");
  const [buttonText, setButtonText] = useState("");

  const [app, setapp] = useState(null);
  const [addOrEdit, setaddOrEdit] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [icon, seticon] = useState("");
  const [DFAppId, setDFAppId] = useState("");
  const [MoyaAppId, setMoyaAppId] = useState("");
  const [folderDialog, setfolderDialog] = useState(false);
  const [JsonPublisher, setJsonPublisher] = useState(false);
  const [moyaKey, setmoyaKey] = useState("");

  const [addApp, setaddApp] = useState({
    id: "",
    name: "Name of your app",
    icon: "",
    url: "Url of your app",
    dfAppId: "",
    moyaKey: "",
    abColor: "",
    sbColor: "",
    useDarkIcons: false,
    showSplash: false,
    showBanner: false,
    showVideoAd: false,
    useGaid: false,
    useLocation: false,
    useStorage: false,
    useDid: false,
    dfapps: null,
  });
  // add axios calls to get regions and moya apps
  useEffect(() => {
    setLoading(true);
    initialAxiosCalls();
  }, []);

  // Render the data
  useEffect(() => {
    // initialAxiosCalls()
    console.log("Run count : ");
    if (individualMoyaApps.length !== 0 && countries.length !== 0) {
      setMoyaApps([...individualMoyaApps]);
      setmoyaAppsIcons([...individualMoyaApps]);
      setRegions([...countries]);
      clearWorkbenchState();
      setLoading(false);
    }
    if (addAppWithNoDFIDStore !== null) {
      // console.log("addAppWithNoDFIDStore : ", addAppWithNoDFIDStore);
      setMoyaApps([ ...individualMoyaApps]);
      setmoyaAppsIcons([...individualMoyaApps]);
      clearWorkbenchState();
      // setLoading(false);
    }
    if (editedMoyaApp !== null) {
      if (moyaApps.length <= 1) {
        console.log("EDITED MOYA APP : ", editedMoyaApp);
        console.log("INDI APPS : ", individualMoyaApps);
        if(individualMoyaApps.indexOf(editedMoyaApp) !== -1) {
          setMoyaApps([...individualMoyaApps]);
          setmoyaAppsIcons([...individualMoyaApps]);
          clearWorkbenchState();  
        //  setLoading(false);
        } else {
          setMoyaApps([editedMoyaApp, ...individualMoyaApps]);
          setmoyaAppsIcons([editedMoyaApp, ...individualMoyaApps]);
          clearWorkbenchState();  
         //  setLoading(false);
          
        }
      } 
    }
  }, [individualMoyaApps, editedMoyaApp]);

  // Manage the errors
  useEffect(() => {
    if (error.id === "ADD_OR_EDIT_MOYA_APP_FAIL") {
      toast.error("Unable to complete the action.");
      clearDfAppsState();
      clearErrors();
    }
    if (error.id === "FAILED_TO_PUBLISH_JSON") {
      toast.error(error.msg);
      clearErrors();
    }
    if (error.id === "DELETE_MOYA_APP_FAIL") {
      toast.error("Unable to delete the app.");
      clearErrors();
    }
    if (status !== null && message !== null) {
      toast.success(message);
      clearDfAppsState();
    }
  }, [error, status, message, editedMoyaApp]);
  const initialAxiosCalls = async () => {
    if (individualMoyaApps.length === 0) {
      getAllMoyaApps();
      getAllRegions();
    }
  };
  //Search function
  const fuse = new Fuse(moyaApps, {
    keys: ["name", "id", "dfAppId"],
    includeScore: true,
    useExtendedSearch: true,
    // threshold: 1
  });
  const results = fuse.search(value);
  let filteredItems = value
    ? results.map((filteredItem) => filteredItem.item)
    : moyaApps;
  // Ends here

  const handleDropdownValueChange = (item) => {
    // console.log("Value from child : ", item);
    // make axios calls
    if (item !== null) {
      selectedRegion({ region: item.code });
    }
  };

  const handleAddMoyaAppIconButtonClick = (item) => {
    // console.log("Selected Region code id : ", selectedRegionCode)
    if (selectedRegionCode.length !== 0) {
      addMoyaAppToRegion({
        folderid: selectedRegionCode[0].id,
        moyaappid: item.id,
      });
    } else {
      toast.error("Please Select a region");
    }
  };

  // Edit the app
  const handleClose = (e) => {
    //  e.preventDefault();
    setDialogOpen(false);
  };
  const handleClickOpen = (e, item, moyaAppEdit) => {
    e.preventDefault();

    if (moyaAppEdit === "moyaAppEditInWorkbench") {
      console.log("Edit : ", item);
      setaddOrEdit("moyaAppEditInWorkbench");
      console.log("MOYA APP EDIT ", moyaAppEdit);
      console.log("MOYA APP ", item.icon);
      setMoyaAppId(item.id);
      setDFAppId(item.dfAppId);
      setName(item.name);
      seticon(item.icon);
      setUrl(item.url);

      setapp(item);

      // setmoyaKey(item.moyaKey)
      setDialogTitle("Edit Datafree app to Moya ");
      setDialogContentText("Edit your DF app to moya discover.");
      setButtonText("Save");

      // console.log("ITEM EDIT : ", MoyaAppId, DFAppId, name, icon, url)
      setDialogOpen(true);
    } else {
      // handle click open
      setapp(addApp, moyaAppEdit);
      setaddOrEdit(moyaAppEdit);
      setDialogTitle("Add Datafree app to Moya ");
      setDialogContentText("Add your individual moya app.");
      setButtonText("Save");

      setDialogOpen(true);
    }
  };

  // JSON PUBLISHER
  const jsonPublisher = (e) => {
    if (selectedRegionCode.length !== null) {
      // console.log("selectedRegionCode.regionCode", folderName, selectedRegionCode[0].regionCode)
      publishJsonForRegion({
        regionCode: selectedRegionCode[0].regionCode,
      });
    }
    setJsonPublisher(false);
  };
  // Ends here
  const handleBackButton = () => {
    selectedRegion({ region: selectedRegionCode[0].regionCode });
  };
  // Close FOlder Dialog
  const handleDialogClose = (e) => {
    //  e.preventDefault();
    setfolderDialog(false);
  };

  //
  
  return (
    <Grid container spacing={2} justifyContent="center">
      {
         loading ? (
          <Spinner message="Processing your request.. " />
        ) : (
          <>
           {/* <Grid container spacing={2} justifyContent="center"> */}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid container justifyContent="center">
                  <Grid item xs={8} md={8} lg={10} xl={8}>
                    <TextField
                      id="outlined-margin-normal"
                      variant="outlined"
                      className={classes.textField}
                      placeholder="Search Moya Apps"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {value === "" ? (
                              <IconButton>
                                <Search />
                              </IconButton>
                            ) : (
                              <IconButton onClick={(e) => setValue("")}>
                                <Close />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
        
                  <Grid item xs={4} md={4} lg={2} xl={2}>
                    <div
                      style={{
                        marginTop: "5%",
                        textAlign: "center",
                        marginLeft: "0%",
                        // color: "white",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<Add />}
                        onClick={(e) => handleClickOpen(e, addApp, "moyaAppWithNoDFID")}
                      >
                        Add Moya App
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                  <Paper className={classes.listPaper}>
                    {/* List for moya apps */}
        
                    <List className={classes.list}>
                      {filteredItems.length !== 0 ? (
                        filteredItems.map((item, index) => (
                          <div>
                            <ListItem key={item.id} alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar
                                  alt={item.icon}
                                  src={item.icon}
                                  // alt={moyaAppsIcons[index].icon}
                                  // src={moyaAppsIcons[index].icon}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                //  inset
                                className={classes.inline}
                                style={{ maxWidth: "60%" }}
                                primary={
                                  item.name.length < 100
                                    ? item.name
                                    : item.name.match(/.{1,50}/g).join("\r\n")
                                }
                                secondary={`DF App ID : ${item.dfAppId} Type : ${
                                  item.dfapps !== null ? item.dfapps.product : ""
                                }`}
                              />
                              <ListItemSecondaryAction style={{ paddingLeft: "5%" }}>
                                <IconButton
                                  onClick={(e) =>
                                    handleClickOpen(e, item, "moyaAppEditInWorkbench")
                                  }
                                >
                                  <Edit />
                                </IconButton>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  endIcon={<ArrowRightAltOutlined />}
                                  onClick={(e) => handleAddMoyaAppIconButtonClick(item)}
                                >
                                  Add
                                </Button>
                              </ListItemSecondaryAction>
                            </ListItem>
                          </div>
                        ))
                      ) : (
                        <p> Processing your request... </p>
                      )}
                    </List>
                    {dialogOpen ? (
                      <AddAndEditDialog
                        app={app}
                        isOpen={dialogOpen}
                        handleClose={handleClose}
                        currentName={name}
                        currentUrl={url}
                        currentIcon={icon}
                        currentDfAppId={DFAppId}
                        dialogTitle={dialogTitle}
                        dialogContentText={dialogContentText}
                        buttonText={buttonText}
                        addOrEdit={addOrEdit}
                        MoyaAppId={MoyaAppId}
                      />
                    ) : null}
                  </Paper>
                </Grid>
              </Grid>
        
              {/* Auto complete & Mobile view grid */}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                {/* <Paper
                  style={{
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "blue"
                  }}
                  elevation={6}
                > */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                      {/* <Paper
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            color: "white",
                          }}
                          elevation={6}
                        > */}
                      <div>
                        <AutocompleteDropdown
                          countries={regions}
                          onSelect={handleDropdownValueChange}
                        />
                      </div>
                      {/* </Paper> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {/* <Paper
                          style={{
                            marginTop:"5%",
                            padding: "10px",
                            textAlign: "center",
                            color: "white",
                          }}
                          elevation={6}
                        > */}
                      <div
                        style={{
                          marginTop: "3%",
                          textAlign: "center",
                          // color: "white",
                        }}
                      >
                        {selectedRegionCode.length === 0 ? (
                          ""
                        ) : selectedRegionCode[0].name === "HOME" ? (
                          <ButtonGroup disableElevation variant="contained">
                            <Button
                              className={classes.newFolderButton}
                              onClick={(e) => setfolderDialog(true)}
                            >
                              <Folder sx={{ mr: 1 }} style={{ padding: "2%" }} />
                              New Folder
                            </Button>
                            <Button
                              className={classes.publishButton}
                              onClick={(e) => setJsonPublisher(true)}
                            >
                              <Publish sx={{ mr: 1 }} style={{ padding: "2%" }} />
                              Publish
                            </Button>
                          </ButtonGroup>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<ArrowBackIos />}
                            onClick={(e) => handleBackButton()}
                          >
                            Back
                          </Button>
                        )}
                      </div>
                      {/* </Paper> */}
                    </Grid>
                  </Grid>
                </Grid>
        
                <Grid item xs={12}>
                  {/* <Paper
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        color: "white",
                        backgroundColor: "green"
                      }}
                      elevation={6}
                    > */}
                  <Mobileview />
                  {/* </Paper> */}
                </Grid>
                {/* FOLDER DIALOG */}
                {folderDialog ? (
                  <FolderAddAndEdit
                    isOpen={folderDialog}
                    handleClose={handleDialogClose}
                    currentName={name}
                    currentUrl={url}
                    currentIcon={icon}
                    dialogTitle="New Folder"
                    dialogContentText="Name of your new folder"
                    buttonText={"Save"}
                    addOrEdit={"FolderAdd"}
                    MoyaAppId={MoyaAppId}
                    currentMoyakey={moyaKey}
                  />
                ) : null}
                {/* Ends here */}
                <Dialog
                  open={JsonPublisher}
                  onClose={(e) => setJsonPublisher(false)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">JSON PUBLISHER</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to publish the current JSON?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={(e) => setJsonPublisher(false)} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={jsonPublisher} color="primary">
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* ENDS HERE */}
        
                {/* </Paper> */}
              </Grid>
              {/* </Grid> */}
              </>
          )
        
      }
    </Grid>
  )
}
const mapStateToProps = (state) => {
  // console.log(state.region.selectedRegion)
  return {
    selectedRegionCode: state.region.selectedRegion,
    user: state.auth.user.email,
    error: state.error,
    status: state.dfApps.status,
    editedMoyaApp: state.dfApps.app,
    message: state.dfApps.message,
    individualMoyaApps: state.region.moyaApps,
    addAppWithNoDFIDStore: state.region.app,
    countries: state.region.regions,
    isLoading: state.region.isLoading,
  };
};
export default connect(mapStateToProps, {
  clearWorkbenchState,
  getAllRegions,
  getAllMoyaApps,
  publishJsonForRegion,
  addFolderForRegion,
  addMoyaAppToRegion,
  selectedRegion,
  clearDfAppsState,
  clearErrors,
})(MoyaWorkbench);
