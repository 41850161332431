import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Button,
  TextField,
  TablePagination,
  Grid,
  InputAdornment,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Avatar,
} from '@material-ui/core';
import {
  Delete,
  Search,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit
} from '@material-ui/icons';
import Fuse from 'fuse.js';
import baseApiReq from '../../api';
import { connect } from 'react-redux';
import Spinner from '../Spinner';
import { getDatafreeApps, clearDfAppsState, deleteMoyaAppDfApps } from '../../redux-helpers/Actions/dfAppsAction'
import { clearErrors } from "../../redux-helpers/Actions/errorAction";
import { toast } from 'react-toastify';
import AddAndEditDialog from '../AddAndEditDialog';

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 50,
    },
    width: "40%"
  }
});
// addMoyaApp, editMoyaApp, dialogOpenFromAddorEdit, isOpen, onclose
function Row({ appAdded, row, deleteMoyaAppDfApps }) {

  // console.log("APP ADDED : ", appAdded);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContentText, setDialogContentText] = useState('')
  const [buttonText, setButtonText] = useState('')

  const [addOrEdit, setaddOrEdit] = useState('')
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [icon, seticon] = useState('')
  const [DFAppId, setDFAppId] = useState('')
  const [MoyaAppId, setMoyaAppId] = useState('')

  const [selectedApp, setselectedApp] = useState(null)
  // Checkboxes and colour
  const [sbColour, setsbColour] = useState('')
  const [abColour, setabColour] = useState('')
  // Checkboxes
  const [useDarkIcons, setUseDarkIcons] = useState(false);
  const [showSplash, setshowSplash] = useState(false)
  const [showBanner, setshowBanner] = useState(false)
  const [showVideoId, setshowVideoId] = useState(false)
  const [useGaid, setuseGaid] = useState(false)
  const [useLocation, setuseLocation] = useState(false)
  const [useStorage, setuseStorage] = useState(false)
  const [useDid, setuseDid] = useState(false)

  useEffect(() => {
  }, [appAdded])
  const handleClose = (e) => {
    //  e.preventDefault();
    setDialogOpen(false)
  };

  const handleClickOpen = (e, item, moyaAppEdit) => {
    e.preventDefault()
    if (moyaAppEdit === "moyaAppEdit") {
      const { sbColour, abColour, useDarkIcons, showSplash, showBanner, showVideoId,
        useGaid, useLocation, useStorage, useDid
      } = item;

      setaddOrEdit("moyaAppEdit")
      // console.log("MOYA APP EDIT ", moyaAppEdit)
      // console.log("MOYA APP ", item.icon)
      setMoyaAppId(item.id)
      setDFAppId(item.dfAppId)
      setName(item.name)
      seticon(item.icon)
      setUrl(item.url)

      setselectedApp(item);

      setsbColour(sbColour)
      setabColour(abColour)
      setUseDarkIcons(useDarkIcons)
      setshowSplash(showSplash)
      setshowBanner(showBanner)
      setshowVideoId(showVideoId)
      setuseGaid(useGaid)
      setuseLocation(useLocation)
      setuseStorage(useStorage)
      setuseDid(useDid)




      setDialogTitle("Edit Datafree app to Moya ")
      setDialogContentText("Edit your DF app to moya discover.")
      setButtonText('Save')

      // console.log("ITEM EDIT : ", MoyaAppId, DFAppId, name, icon, url)
      setDialogOpen(true)


    }
    else {
      setaddOrEdit('')
      setName(item.name)
      seticon(item.icon_url)
      setDFAppId(item.app_id)
      setUrl(item.url)
      const newObject = {
        ...item, dfapps: { product: item.product },
        abColour,
        sbColour,
        useDarkIcons,
        showSplash,
        showBanner,
        showVideoId,
        useGaid,
        useLocation,
        useStorage,
        useDid
      }
      // console.log("ITEM ADD : ", newObject)
      setselectedApp(newObject);

      setDialogTitle("Add Datafree app to Moya")
      setDialogContentText("Add your DF app to moya discover.")
      setButtonText('Add')
      //  console.log("ITEM Add : ", MoyaAppId, DFAppId, name, icon, url)
      setDialogOpen(true)
    }
  };


  // 
  const handleDeleteMoyaApp = (e, item) => {
    // console.log(item)
    deleteMoyaAppDfApps({ MoyaAppID: item })
  }
  //
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          {/* <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton> */}

          {
            appAdded !== null && appAdded.dfAppId === row.app_id ? (
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {!open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            ) : (
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )
          }
        </TableCell>
        <TableCell align="center">
          {/* {row.icon_url} */}
          <div style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Avatar
              alt="Icon"
              src={row.icon_url}
            />
          </div>
        </TableCell>
        <TableCell align="center">{row.app_id}</TableCell>
        <TableCell align="center">{row.name}</TableCell>
        <TableCell align="center">{row.product}</TableCell>
        <TableCell align="center">
          <Button variant="contained"
            color="primary" onClick={e => handleClickOpen(e, row)}>
            Add
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          {
            appAdded !== null && appAdded.dfAppId === row.app_id ? (
              <Collapse in={!open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Typography variant="h6" gutterBottom component="div">
                    Moya Apps
                  </Typography>
                  <Table size="small" aria-label="moyaApps">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">icon</TableCell>
                        <TableCell align="center">id</TableCell>
                        <TableCell align="center">name</TableCell>
                        <TableCell align="center">url</TableCell>
                        <TableCell align="center">Edit</TableCell>
                        <TableCell align="center">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                  
                    <TableBody>
                      {row.mdapps ? row.mdapps.map((moyaAppRow) => (
                        <TableRow key={moyaAppRow.id}>
                          <TableCell align="center" >
                            <div style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                              <Avatar
                                alt={moyaAppRow.icon}
                                src={moyaAppRow.icon}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="center">{moyaAppRow.id}</TableCell>
                          <TableCell align="center">{moyaAppRow.name}</TableCell>
                          <TableCell align="center">{moyaAppRow.url}</TableCell>
                          <TableCell align="center">
                            <IconButton onClick={e => handleClickOpen(e, moyaAppRow, "moyaAppEdit")}>
                              <Edit />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            {/* <DeleteMoyaApp MoyaAppID={moyaAppRow.id} />
         */}
                            <IconButton onClick={e => handleDeleteMoyaApp(e, moyaAppRow.id)}>
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )) : ""}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            ) : (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Typography variant="h6" gutterBottom component="div">
                    Moya Apps
                  </Typography>
                  <Table size="small" aria-label="moyaApps">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">icon</TableCell>
                        <TableCell align="center">id</TableCell>
                        <TableCell align="center">name</TableCell>
                        <TableCell align="center">url</TableCell>
                        <TableCell align="center">Edit</TableCell>
                        <TableCell align="center">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.mdapps ? row.mdapps.map((moyaAppRow) => (
                        <TableRow key={moyaAppRow.id}>
                          <TableCell align="center" >
                            <div style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                              <Avatar
                                alt={moyaAppRow.icon}
                                src={moyaAppRow.icon}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="center">{moyaAppRow.id}</TableCell>
                          <TableCell align="center">{moyaAppRow.name}</TableCell>
                          <TableCell align="center">{moyaAppRow.url}</TableCell>
                          <TableCell align="center">
                            <IconButton onClick={e => handleClickOpen(e, moyaAppRow, "moyaAppEdit")}>
                              <Edit />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            {/* <DeleteMoyaApp MoyaAppID={moyaAppRow.id} />
         */}
                            <IconButton onClick={e => handleDeleteMoyaApp(e, moyaAppRow.id)}>
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )) : ""}
                    </TableBody>
               
                  </Table>
                </Box>
              </Collapse>
            )

          }

        </TableCell>
      </TableRow>


      {
        dialogOpen ? (
          <AddAndEditDialog
            isOpen={dialogOpen} handleClose={handleClose} currentName={name} currentUrl={url} currentIcon={icon} currentDfAppId={DFAppId}
            dialogTitle={dialogTitle} dialogContentText={dialogContentText} buttonText={buttonText} addOrEdit={addOrEdit} MoyaAppId={MoyaAppId}
            app={selectedApp}
          />
        ) : null
      }
    </React.Fragment>
  );
}

function DFapps({
  appAdded,
  deleteMoyaAppDfApps, clearErrors, getDatafreeApps,
  clearDfAppsState, addMoyaApp, editMoyaApp, addApp, isLoading, error, dfApps, status, message }) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false)



  useEffect(() => {
    initialAxiosCalls();
  }, [])

  useEffect(() => {
    if (error.id === 'ADD_OR_EDIT_MOYA_APP_FAIL') {
      //   toast.error("Unable to complete the action.")
      clearErrors();
      clearDfAppsState();
    }
    if (error.id === "DELETE_MOYA_APP_FAIL") {
      toast.error("Unable to complete the action.")
      clearErrors();
      // clearDfAppsState()
    }
    if (status !== null && message !== null) {
      toast.success(message);
      clearDfAppsState();
    }

  }, [error, status, message, appAdded])

  // get Initial Data for all the apps
  const initialAxiosCalls = async () => {
    setLoading(true)
    // console.log("TOKEN : ", baseApiReq)
    await baseApiReq.get("/dfapps/all").then(res => {
      // console.log(res.data)
      setLoading(false)
      toast.success("Successfully fetched Datafree and Moya apps.")
      getDatafreeApps([...res.data.data])

    }).catch(err => {
      //   console.log("ERROR", err)
      setLoading(false)
      clearErrors()
      toast.error("Unable to get datafree apps. Please try again later.")
      // return alert("Unable to get datafree apps. Please try again later.");
    })
  }
  //Paggination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  // Ends here

  //Search function
  const fuse = new Fuse(dfApps, {
    keys: [
      "name",
      "app_id",
      "mdapps.name"
    ],
    includeScore: true,
    // threshold: 1
  });
  const results = fuse.search(value);
  let filteredItems = value ? results.map(filteredItem => filteredItem.item) : dfApps;
  // Ends here

  return (
    loading ? <Spinner /> : (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item sm={12} md={12} lg={10} >

          <TextField
            id="outlined-margin-normal"
            margin="normal"
            variant="outlined"
            className={classes.textField}
            placeholder="Search Datafree Apps"
            value={value}
            onChange={e => setValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                >
                  {
                    value === '' ? (
                      <IconButton>
                        <Search />
                      </IconButton>
                    ) :
                      <IconButton onClick={e => setValue('')}>
                        <Close />
                      </IconButton>
                  }
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={10} >
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="center">Icon</TableCell>
                  <TableCell align="center">App ID</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Product</TableCell>
                  <TableCell align="center">Add to Moya store</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  isLoading ? (
                    <Spinner message="Processing your request.. " />
                  ) : filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <Row
                      appAdded={appAdded}
                      deleteMoyaAppDfApps={deleteMoyaAppDfApps}
                      key={row.app_id} row={row} addMoyaApp={addMoyaApp} editMoyaApp={editMoyaApp} />
                  ))
                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>

        </Grid>
      </Grid>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    appAdded: state.dfApps.app,
    dfApps: state.dfApps.dfApps,
    addApp: state.dfApps,
    isLoading: state.dfApps.isLoading,
    error: state.error,
    status: state.dfApps.status,
    message: state.dfApps.message,
  };
};

export default connect(mapStateToProps, { deleteMoyaAppDfApps, clearErrors, getDatafreeApps, clearDfAppsState })(DFapps)