import React from "react";
import {
  makeStyles,
  Grid
} from '@material-ui/core';
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

import SignIn from "../components/auth/SignIn";
// import Register from "../components/auth/Register";
import MoyaWorkbench from "../components/workbench/MoyaWorkbench";
import Appbar from "../components/Appbar";
import DFapps from "../components/DFapps/DFapps"
import { Toast } from "../components/Toast";
import ForgotPassword from "../components/auth/ForgotPassword";
const useStyles = makeStyles((theme) => ({
  appbar: {
    marginBottom: "2%"
  }
}));

const auth = {
  token: null,
  isAuthenticated: null,
};



// Routes is the function which navigates throughout the application
const Routes = ({ isAuthenticated, token, alert }) => {
  auth.isAuthenticated = isAuthenticated;
  auth.token = token;
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      {/* <Route path="/signin" exact component={SignIn} /> */}
      <Route path="/forgotPassword" exact component={ForgotPassword} />
      {/* <Route path="/MoyaWorkbench" exact component={MoyaWorkbench} />
      <Route path="/DFapps" exact component={DFapps} /> */}
      <RouteProtected path="/MoyaWorkbench" exact component={MoyaWorkbench} />
      <RouteProtected path="/DFapps" exact component={DFapps} />

    </Switch>
  );
}

// RouteProtected is used to protect the routes
// Protected routes verify the token and isAuthenticated from your store.
// Component parameter renders the component
// and ...rest parameter is props of the component that you are passing
const RouteProtected = ({ component: Component, alert, ...rest }) => {
  const classes = useStyles();
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated && auth.token !== null ? (

          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.appbar}>
              <Appbar />
              <Toast />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={11}>
              <Component {...props} />
            </Grid>
          </Grid>

        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  // console.log("Routes : ", state.auth);
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  };
};
export default connect(mapStateToProps, {})(Routes);
